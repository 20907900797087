const survey = {
  'survey_id': 1000,
  'survey_name': 'Watchman Spur',
  'parent_survey_id': 0,
  'campaign': 'main',
  'entry_title': 'Primary eligibility survey',
  'questions': [
    {
      'id': 1001,
      'survey_id': 1000,
      'question_order': 1,
      'question_text': 'Do you have atrial fibrillation (AFib) not caused by a heart valve problem?',
      'question_type': 'radio',
      'required': true,
      'validation_type': '',
      'visibility_checker': [],
      'order_checker': [],
      'question_options': [
        {
          'id': 10011,
          'survey_id': 1000,
          'question_id': 1001,
          'option_text': '<div class=\'icon yes\'></div>Yes',
          'option_order': 1,
          'selection_checker': {
            'deselect_options': []
          },
          'parent_option_id': 0,
          'hint': null,
          'sub_type': '',
          'sub_order': 0,
          'sub_group': null,
          'sub_required': true,
          'sub_options': null,
          'validation_type': ''
        },
        {
          'id': 10012,
          'survey_id': 1000,
          'question_id': 1001,
          'option_text': '<div class=\'icon no\'></div>No',
          'option_order': 2,
          'selection_checker': {
            'deselect_options': []
          },
          'parent_option_id': 0,
          'hint': null,
          'sub_type': '',
          'sub_order': 0,
          'sub_group': null,
          'sub_required': true,
          'sub_options': null,
          'validation_type': ''
        }
      ]
    },
    {
      'id': 1002,
      'survey_id': 1000,
      'question_order': 2,
      'question_text': 'Have you ever been prescribed a blood thinner due to your AFib? <span class=\'emphasis\'>(Even if you are no longer taking it)</span><span class=\'note radios-note\'>Note: Aspirin is not a blood thinner</span>',
      'question_type': 'radio',
      'required': true,
      'validation_type': '',
      'visibility_checker': [],
      'order_checker': [
        {
          'prev_question_id': 1001,
          'option_id': 0
        }
      ],
      'question_options': [
        {
          'id': 10021,
          'survey_id': 1000,
          'question_id': 1002,
          'option_text': '<div class=\'icon yes\'></div>Yes',
          'option_order': 1,
          'selection_checker': {
            'deselect_options': []
          },
          'parent_option_id': 0,
          'hint': null,
          'sub_type': '',
          'sub_order': 0,
          'sub_group': null,
          'sub_required': true,
          'sub_options': null,
          'validation_type': ''
        },
        {
          'id': 10022,
          'survey_id': 1000,
          'question_id': 1002,
          'option_text': '<div class=\'icon no\'></div>No',
          'option_order': 2,
          'selection_checker': {
            'deselect_options': []
          },
          'parent_option_id': 0,
          'hint': null,
          'sub_type': '',
          'sub_order': 0,
          'sub_group': null,
          'sub_required': true,
          'sub_options': null,
          'validation_type': ''
        }
      ]
    },
    {
      'id': 1003,
      'survey_id': 1000,
      'question_order': 3,
      'question_text': 'Gender and age can affect your stroke risk, please tell us about yourself...',
      'question_type': 'radio',
      'required': true,
      'validation_type': '',
      'visibility_checker': [],
      'order_checker': [
        {
          'prev_question_id': 1002,
          'option_id': 0
        }
      ],
      'question_options': [
        {
          'id': 10031,
          'survey_id': 1000,
          'question_id': 1003,
          'option_text': '<div class=\'icon female\'></div>Female',
          'option_order': 1,
          'selection_checker': {
            'deselect_options': []
          },
          'parent_option_id': 0,
          'hint': null,
          'sub_type': '',
          'sub_order': 0,
          'sub_group': null,
          'sub_required': true,
          'sub_options': null,
          'validation_type': ''
        },
        {
          'id': 10032,
          'survey_id': 1000,
          'question_id': 1003,
          'option_text': '<div class=\'icon male\'></div>Male',
          'option_order': 2,
          'selection_checker': {
            'deselect_options': []
          },
          'parent_option_id': 0,
          'hint': null,
          'sub_type': '',
          'sub_order': 0,
          'sub_group': null,
          'sub_required': true,
          'sub_options': null,
          'validation_type': ''
        }
      ]
    },
    {
      'id': 1004,
      'survey_id': 1000,
      'question_order': 4,
      'question_text': 'Have you ever been diagnosed with any of the following conditions? <span class=\'note checkboxes-note\'>Select all that apply</span>',
      'question_type': 'checkbox',
      'required': true,
      'validation_type': '',
      'visibility_checker': [],
      'order_checker': [
        {
          'prev_question_id': 1003,
          'option_id': 0
        }
      ],
      'question_options': [
        {
          'id': 10041,
          'survey_id': 1000,
          'question_id': 1004,
          'option_text': 'Hypertension <span>(elevated blood pressure)</span>',
          'option_order': 1,
          'selection_checker': {
            'deselect_options': [
              100410
            ]
          },
          'parent_option_id': 0,
          'hint': null,
          'sub_type': '',
          'sub_order': 0,
          'sub_group': null,
          'sub_required': true,
          'sub_options': null,
          'validation_type': ''
        },
        {
          'id': 10042,
          'survey_id': 1000,
          'question_id': 1004,
          'option_text': 'Diabetes',
          'option_order': 2,
          'selection_checker': {
            'deselect_options': [
              100410
            ]
          },
          'parent_option_id': 0,
          'hint': null,
          'sub_type': '',
          'sub_order': 0,
          'sub_group': null,
          'sub_required': true,
          'sub_options': null,
          'validation_type': ''
        },
        {
          'id': 10043,
          'survey_id': 1000,
          'question_id': 1004,
          'option_text': 'Congestive Heart Failure',
          'option_order': 3,
          'selection_checker': {
            'deselect_options': [
              100410
            ]
          },
          'parent_option_id': 0,
          'hint': null,
          'sub_type': '',
          'sub_order': 0,
          'sub_group': null,
          'sub_required': true,
          'sub_options': null,
          'validation_type': ''
        },
        {
          'id': 10044,
          'survey_id': 1000,
          'question_id': 1004,
          'option_text': 'Stroke, Mini-Stroke or Thromboembolism',
          'option_order': 4,
          'selection_checker': {
            'deselect_options': [
              100410
            ]
          },
          'parent_option_id': 0,
          'hint': null,
          'sub_type': '',
          'sub_order': 0,
          'sub_group': null,
          'sub_required': true,
          'sub_options': null,
          'validation_type': ''
        },
        {
          'id': 10045,
          'survey_id': 1000,
          'question_id': 1004,
          'option_text': 'Cancer',
          'option_order': 5,
          'selection_checker': {
            'deselect_options': [
              100410
            ]
          },
          'parent_option_id': 0,
          'hint': null,
          'sub_type': '',
          'sub_order': 0,
          'sub_group': null,
          'sub_required': true,
          'sub_options': null,
          'validation_type': ''
        },
        {
          'id': 10046,
          'survey_id': 1000,
          'question_id': 1004,
          'option_text': 'Vascular Disease',
          'option_order': 6,
          'selection_checker': {
            'deselect_options': [
              100410
            ]
          },
          'parent_option_id': 0,
          'hint': null,
          'sub_type': '',
          'sub_order': 0,
          'sub_group': null,
          'sub_required': true,
          'sub_options': null,
          'validation_type': ''
        },
        {
          'id': 10047,
          'survey_id': 1000,
          'question_id': 1004,
          'option_text': 'Abnormal Liver Function',
          'option_order': 7,
          'selection_checker': {
            'deselect_options': [
              100410
            ]
          },
          'parent_option_id': 0,
          'hint': null,
          'sub_type': '',
          'sub_order': 0,
          'sub_group': null,
          'sub_required': true,
          'sub_options': null,
          'validation_type': ''
        },
        {
          'id': 10048,
          'survey_id': 1000,
          'question_id': 1004,
          'option_text': 'Abnormal Kidney (Renal) Function',
          'option_order': 8,
          'selection_checker': {
            'deselect_options': [
              100410
            ]
          },
          'parent_option_id': 0,
          'hint': null,
          'sub_type': '',
          'sub_order': 0,
          'sub_group': null,
          'sub_required': true,
          'sub_options': null,
          'validation_type': ''
        },
        {
          'id': 10049,
          'survey_id': 1000,
          'question_id': 1004,
          'option_text': 'Dementia or Alzheimer\'s',
          'option_order': 9,
          'selection_checker': {
            'deselect_options': [
              100410
            ]
          },
          'parent_option_id': 0,
          'hint': null,
          'sub_type': '',
          'sub_order': 0,
          'sub_group': null,
          'sub_required': true,
          'sub_options': null,
          'validation_type': ''
        },
        {
          'id': 100410,
          'survey_id': 1000,
          'question_id': 1004,
          'option_text': 'None of the above',
          'option_order': 10,
          'selection_checker': {
            'deselect_options': [
              10041,
              10042,
              10043,
              10044,
              10045,
              10046,
              10047,
              10048,
              10049
            ]
          },
          'parent_option_id': 0,
          'hint': null,
          'sub_type': '',
          'sub_order': 0,
          'sub_group': null,
          'sub_required': true,
          'sub_options': null,
          'validation_type': ''
        }
      ]
    },
    {
      'id': 1005,
      'survey_id': 1000,
      'question_order': 5,
      'question_text': 'Many people may experience side effects from taking blood thinners, do any of these apply to you? <span class=\'note checkboxes-note\'>Select all that apply</span>',
      'question_type': 'checkbox',
      'required': true,
      'validation_type': '',
      'visibility_checker': [],
      'order_checker': [
        {
          'prev_question_id': 1004,
          'option_id': 0
        }
      ],
      'question_options': [
        {
          'id': 10051,
          'survey_id': 1000,
          'question_id': 1005,
          'option_text': 'I have experienced major bleeding. <span class=\'hard-break\'>Example: GI bleeding, bleeding in the brain or head</span>',
          'option_order': 1,
          'selection_checker': {
            'deselect_options': [
              10058
            ]
          },
          'parent_option_id': 0,
          'hint': null,
          'sub_type': '',
          'sub_order': 0,
          'sub_group': null,
          'sub_required': true,
          'sub_options': null,
          'validation_type': ''
        },
        {
          'id': 10052,
          'survey_id': 1000,
          'question_id': 1005,
          'option_text': 'I have experienced minor bleeding. <span class=\'hard-break\'>Example: Nose bleeds or easy bruising</span>',
          'option_order': 2,
          'selection_checker': {
            'deselect_options': [
              10058
            ]
          },
          'parent_option_id': 0,
          'hint': null,
          'sub_type': '',
          'sub_order': 0,
          'sub_group': null,
          'sub_required': true,
          'sub_options': null,
          'validation_type': ''
        },
        {
          'id': 10053,
          'survey_id': 1000,
          'question_id': 1005,
          'option_text': 'I have a bleeding risk from other health conditions. <span class=\'hard-break\'>Example: Thrombocytopenia (low platelets), cancer or tumor-associated bleeding</span>',
          'option_order': 3,
          'selection_checker': {
            'deselect_options': [
              10058
            ]
          },
          'parent_option_id': 0,
          'hint': null,
          'sub_type': '',
          'sub_order': 0,
          'sub_group': null,
          'sub_required': true,
          'sub_options': null,
          'validation_type': ''
        },
        {
          'id': 10054,
          'survey_id': 1000,
          'question_id': 1005,
          'option_text': 'I suffer from decreased stability or have mobility issues that increase my risk of falling.',
          'option_order': 4,
          'selection_checker': {
            'deselect_options': [
              10058
            ]
          },
          'parent_option_id': 0,
          'hint': null,
          'sub_type': '',
          'sub_order': 0,
          'sub_group': null,
          'sub_required': true,
          'sub_options': null,
          'validation_type': ''
        },
        {
          'id': 10055,
          'survey_id': 1000,
          'question_id': 1005,
          'option_text': 'My job requires me to perform duties that may put me at risk for bleeding or falling. <span class=\'hard-break\'>Example: Lifting, using power tools, climbing ladders, cutting, etc.</span>',
          'option_order': 5,
          'selection_checker': {
            'deselect_options': [
              10058
            ]
          },
          'parent_option_id': 0,
          'hint': null,
          'sub_type': '',
          'sub_order': 0,
          'sub_group': null,
          'sub_required': true,
          'sub_options': null,
          'validation_type': ''
        },
        {
          'id': 10056,
          'survey_id': 1000,
          'question_id': 1005,
          'option_text': 'I have a hobby that puts me at risk for bleeding or falling. <span class=\'hard-break\'>Example: Cooking, biking, running, gardening or fishing</span>',
          'option_order': 6,
          'selection_checker': {
            'deselect_options': [
              10058
            ]
          },
          'parent_option_id': 0,
          'hint': null,
          'sub_type': '',
          'sub_order': 0,
          'sub_group': null,
          'sub_required': true,
          'sub_options': null,
          'validation_type': ''
        },
        {
          'id': 10057,
          'survey_id': 1000,
          'question_id': 1005,
          'option_text': 'I have made lifestyle tradeoffs due to my blood thinners. <span class=\'hard-break\'>Example: Reduced time spent on hobbies, work, social engagements or decreased quality of life</span>',
          'option_order': 7,
          'selection_checker': {
            'deselect_options': [
              10058
            ]
          },
          'parent_option_id': 0,
          'hint': null,
          'sub_type': '',
          'sub_order': 0,
          'sub_group': null,
          'sub_required': true,
          'sub_options': null,
          'validation_type': ''
        },
        {
          'id': 10058,
          'survey_id': 1000,
          'question_id': 1005,
          'option_text': 'None of the above',
          'option_order': 8,
          'selection_checker': {
            'deselect_options': [
              10051,
              10052,
              10053,
              10054,
              10055,
              10056,
              10057
            ]
          },
          'parent_option_id': 0,
          'hint': null,
          'sub_type': '',
          'sub_order': 0,
          'sub_group': null,
          'sub_required': true,
          'sub_options': null,
          'validation_type': ''
        }
      ]
    },
    {
      'id': 1006,
      'survey_id': 1000,
      'question_order': 6,
      'question_text': 'You are almost done! Have you had any of these issues while on blood thinners? <span class=\'note checkboxes-note\'>Select all that apply</span>',
      'question_type': 'checkbox',
      'required': true,
      'validation_type': '',
      'visibility_checker': [],
      'order_checker': [
        {
          'prev_question_id': 1005,
          'option_id': 0
        }
      ],
      'question_options': [
        {
          'id': 100610,
          'survey_id': 0,
          'question_id': 1006,
          'option_text': 'Enter any other issues you are having (optional)',
          'option_order': 0,
          'selection_checker': {
            'deselect_options': []
          },
          'parent_option_id': 10066,
          'hint': '',
          'sub_type': 'input-textarea',
          'sub_order': 0,
          'sub_group': '',
          'sub_required': false,
          'sub_options': null,
          'validation_type': ''
        },
        {
          'id': 10061,
          'survey_id': 1000,
          'question_id': 1006,
          'option_text': 'I struggle with medication side effects.',
          'option_order': 1,
          'selection_checker': {
            'deselect_options': [
              10067
            ]
          },
          'parent_option_id': 0,
          'hint': null,
          'sub_type': '',
          'sub_order': 0,
          'sub_group': null,
          'sub_required': true,
          'sub_options': null,
          'validation_type': ''
        },
        {
          'id': 10062,
          'survey_id': 1000,
          'question_id': 1006,
          'option_text': 'I have a hard time remembering to take my medications.',
          'option_order': 2,
          'selection_checker': {
            'deselect_options': [
              10067
            ]
          },
          'parent_option_id': 0,
          'hint': null,
          'sub_type': '',
          'sub_order': 0,
          'sub_group': null,
          'sub_required': true,
          'sub_options': null,
          'validation_type': ''
        },
        {
          'id': 10063,
          'survey_id': 1000,
          'question_id': 1006,
          'option_text': 'I struggle with staying within clotting range.',
          'option_order': 3,
          'selection_checker': {
            'deselect_options': [
              10067
            ]
          },
          'parent_option_id': 0,
          'hint': null,
          'sub_type': '',
          'sub_order': 0,
          'sub_group': null,
          'sub_required': true,
          'sub_options': null,
          'validation_type': ''
        },
        {
          'id': 10064,
          'survey_id': 1000,
          'question_id': 1006,
          'option_text': 'I have a hard time keeping my regular INR blood testing appointments.',
          'option_order': 4,
          'selection_checker': {
            'deselect_options': [
              10067
            ]
          },
          'parent_option_id': 0,
          'hint': null,
          'sub_type': '',
          'sub_order': 0,
          'sub_group': null,
          'sub_required': true,
          'sub_options': null,
          'validation_type': ''
        },
        {
          'id': 10065,
          'survey_id': 1000,
          'question_id': 1006,
          'option_text': 'Having to limit alcoholic drinks to less than 8 per week.',
          'option_order': 5,
          'selection_checker': {
            'deselect_options': [
              10067
            ]
          },
          'parent_option_id': 0,
          'hint': null,
          'sub_type': '',
          'sub_order': 0,
          'sub_group': null,
          'sub_required': true,
          'sub_options': null,
          'validation_type': ''
        },
        {
          'id': 10068,
          'survey_id': 1000,
          'question_id': 1006,
          'option_text': 'I am on long-term dual antiplatelet therapy also known as DAPT. <span class=\'hard-break\'>Example: clopidogrel, prasugrel or ticagrelor</span>',
          'option_order': 6,
          'selection_checker': {
            'deselect_options': [
              10067
            ]
          },
          'parent_option_id': 0,
          'hint': null,
          'sub_type': '',
          'sub_order': 0,
          'sub_group': null,
          'sub_required': true,
          'sub_options': null,
          'validation_type': ''
        },
        {
          'id': 10069,
          'survey_id': 1000,
          'question_id': 1006,
          'option_text': 'I have a need for prolonged aspirin or NSAIDs use <span class=\'italic\'>(such as ibuprofen, Aleve, etc)</span>.',
          'option_order': 7,
          'selection_checker': {
            'deselect_options': [
              10067
            ]
          },
          'parent_option_id': 0,
          'hint': null,
          'sub_type': '',
          'sub_order': 0,
          'sub_group': null,
          'sub_required': true,
          'sub_options': null,
          'validation_type': ''
        },
        {
          'id': 10066,
          'survey_id': 1000,
          'question_id': 1006,
          'option_text': 'I have other major issues.',
          'option_order': 8,
          'selection_checker': {
            'deselect_options': [
              10067
            ]
          },
          'parent_option_id': 0,
          'hint': null,
          'sub_type': '',
          'sub_order': 0,
          'sub_group': null,
          'sub_required': true,
          'sub_options': null,
          'validation_type': ''
        },
        {
          'id': 10067,
          'survey_id': 1000,
          'question_id': 1006,
          'option_text': 'None of the above',
          'option_order': 9,
          'selection_checker': {
            'deselect_options': [
              10061,
              10062,
              10063,
              10064,
              10065,
              10066,
              10068,
              10069
            ]
          },
          'parent_option_id': 0,
          'hint': null,
          'sub_type': '',
          'sub_order': 0,
          'sub_group': null,
          'sub_required': true,
          'sub_options': null,
          'validation_type': ''
        }
      ]
    }
  ]
}
export default survey
