const surveyClickMiniLqId4 = {
  'id': 4,
  'questions': {
    '16': {
      'id': 16,
      'text': 'Are you satisfied with your doctors answer about if the WATCHMAN Implant is right for you?',
      'type': '',
      'options': [
        {
          'id': 1,
          'text': 'Yes',
          'type': 'button',
          'icon': 'iconCheckScallop',
          'group_name': 'are_you_satisfied'
        },
        {
          'id': 58,
          'text': 'No',
          'type': 'button',
          'icon': 'iconXCircled',
          'group_name': 'are_you_satisfied'
        },
        {
          'id': 60,
          'text': 'Skip question',
          'type': 'skip',
          'icon': null,
          'group_name': 'are_you_satisfied'
        }
      ]
    },
    '17': {
      'id': 17,
      'text': 'Welcome to the WATCHMAN family!',
      'type': 'final',
      'options': [
        {
          'id': 59,
          'text': 'questionOption placeholder',
          'type': 'component:resources(register)',
          'icon': null,
          'group_name': 'welcome_to_the_family'
        }
      ]
    },
    '18': {
      'id': 18,
      'text': 'Here are resources to help you prepare for what\'s next:',
      'type': 'final',
      'options': [
        {
          'id': 59,
          'text': 'questionOption placeholder',
          'type': 'component:resources(ambassador,specialist)',
          'icon': null,
          'group_name': 'here_are_resources'
        }
      ]
    },
    '19': {
      'id': 19,
      'text': 'Here are resources to help continue exploring the WATCHMAN Implant:  ',
      'type': 'final',
      'options': [
        {
          'id': 59,
          'text': 'questionOption placeholder',
          'type': 'component:resources(specialist,knowYourRisk,ambassador)',
          'icon': null,
          'group_name': 'here_are_resources'
        }
      ]
    },
    '20': {
      'id': 20,
      'text': '<span>Did you know your eligibility can change over time?</span> Consider the WATCHMAN Implant in the future if you experience issues while being on a blood thinner or develop other health conditions.',
      'type': 'final',
      'options': [
        {
          'id': 59,
          'text': 'questionOption placeholder',
          'type': 'component:resources(knowYourRisk)',
          'icon': null,
          'group_name': 'consider_watchman'
        }
      ]
    },
    '21': {
      'id': 21,
      'text': 'Here are resources to help you prepare for what\'s next:',
      'type': 'final',
      'options': [
        {
          'id': 59,
          'text': 'questionOption placeholder',
          'type': 'component:resources(specialist,knowYourRisk,getYourDdg)',
          'icon': null,
          'group_name': 'here_are_resources'
        }
      ]
    },
    '12': {
      'id': 12,
      'text': 'Where are you on your path to the WATCHMAN Implant? <span>Let us know so we can personalize your experience with information & resources to help you prepare for what\'s ahead.</span>',
      'type': 'survey_start',
      'options': [
        {
          'id': 44,
          'text': 'I\'m researching the WATCHMAN Implant',
          'type': 'button',
          'icon': 'iconLaptop',
          'group_name': 'where_on_path'
        },
        {
          'id': 45,
          'text': 'I\'ve discussed the WATCHMAN Implant with my doctor',
          'type': 'button',
          'icon': 'iconChecklist',
          'group_name': 'where_on_path'
        },
        {
          'id': 46,
          'text': 'I already have the WATCHMAN Implant',
          'type': 'button',
          'icon': 'iconInfinity',
          'group_name': 'where_on_path'
        }
      ]
    },
    '13': {
      'id': 13,
      'text': '<span>We\'re glad to hear you are exploring life without blood thinners!</span> Have you discussed the WATCHMAN Implant with your doctor?',
      'type': '',
      'options': [
        {
          'id': 49,
          'text': 'Yes, and my doctor says I\'m a candidate',
          'type': 'button',
          'icon': 'iconCheckScallop',
          'group_name': 'have_you_discussed'
        },
        {
          'id': 50,
          'text': 'Yes, but my doctor says I\'m not a candidate',
          'type': 'button',
          'icon': 'iconXCircled',
          'group_name': 'have_you_discussed'
        },
        {
          'id': 51,
          'text': 'No, not yet',
          'type': 'button',
          'icon': 'iconClockArrowCircular',
          'group_name': 'have_you_discussed'
        }
      ]
    },
    '14': {
      'id': 14,
      'text': 'What did you and your doctor decide about the WATCHMAN Implant?',
      'type': '',
      'options': [
        {
          'id': 54,
          'text': 'My doctor says I am a candidate',
          'type': 'button',
          'icon': 'iconCheckScallop',
          'group_name': 'what_did_you_decide'
        },
        {
          'id': 55,
          'text': 'My doctor says I\'m not a candidate',
          'type': 'button',
          'icon': 'iconXCircled',
          'group_name': 'what_did_you_decide'
        }
      ]
    },
    '15': {
      'id': 15,
      'text': '',
      'type': '',
      'options': [
        {
          'id': 56,
          'text': 'doctor is found, supplying contactId',
          'type': 'component:doctor(search)',
          'icon': null,
          'group_name': 'would_you_mind_sharing'
        },
        {
          'id': 57,
          'text': 'doctor is entered manually, supplying name string',
          'type': 'component:doctor(manual)',
          'icon': null,
          'group_name': 'would_you_mind_sharing'
        },
        {
          'id': 60,
          'text': 'Skip question',
          'type': 'skip',
          'icon': null,
          'group_name': 'would_you_mind_sharing'
        }
      ]
    }
  },
  'meta': {
    'initial_question': 12,
    'question_mapping': {
      '16': {
        'option_mapping': {
          '1': 20,
          '58': 19,
          '60': 20
        }
      },
      '17': {
        'option_mapping': {
          '59': null
        }
      },
      '18': {
        'option_mapping': {
          '59': null
        }
      },
      '19': {
        'option_mapping': {
          '59': null
        }
      },
      '20': {
        'option_mapping': {
          '59': null
        }
      },
      '21': {
        'option_mapping': {
          '59': null
        }
      },
      '12': {
        'option_mapping': {
          '44': 13,
          '45': 14,
          '46': 17
        }
      },
      '13': {
        'option_mapping': {
          '49': 15,
          '50': 16,
          '51': 21
        }
      },
      '14': {
        'option_mapping': {
          '54': 15,
          '55': 16
        }
      },
      '15': {
        'option_mapping': {
          '56': 18,
          '57': 18,
          '60': 18
        }
      }
    }
  },
  'previousAnswers': null
}

module.exports = surveyClickMiniLqId4
