module.exports = {
  first_name_required: 'First name is required.',
  last_name_required: 'Last name is required.',
  name_characters_invalid: 'Sorry, this doesn\'t look like a valid name. Please use letters and try again.',
  text_characters_invalid: 'Invalid symbols. Please use letters and try again.',
  zip_code_required: 'Zip code is required.',
  zip_code_length: 'Please enter a valid 5 digit zip code.',
  zip_code_invalid: 'Please enter a valid zip code.',
  email_address_required: 'Email address is required.',
  email_address_invalid: 'Please enter a valid email address (e.g. example@mail.com).',
  phone_number_invalid: 'Please enter a phone number in the correct format (e.g. (555) 555-5555).',
  phone_number_required: 'Phone number is required.',
  phone_number_length: 'Please enter a 10 digit phone number.',
  phone_modal_phone_number: 'This field is required if you want a WATCHMAN Education Specialist to call you. ' +
    'Please enter a 10 digit phone number.',
  dob_invalid: 'Please enter a valid date of birth in the correct format (e.g. 01/31/1930).',
  age_inappropriate: 'Your age must be between 18 and 120 years.',
  date_invalid: 'Please enter a valid date and in the correct format (e.g. 01/31/2000).',
  date_past_invalid: 'Please enter a valid date in the past.',
  date_future_invalid: 'Please enter a valid date in the future.',
  textarea_length_invalid: '255 character limit',
  large_textarea_length_invalid: '1500 character limit',
  care_team: {
    auth_invalid: 'Please enter your authorization code.',
    auth_failed: 'Please enter a valid authorization code.'
  },
  ambassador: {
    day_of_week: 'Please select a day of the week.',
    time_of_day: 'Please select a time of the day.'
  },
  generic: {
    field_required: 'This field is required.',
    radio_button: 'Please make a selection.',
    check_box: 'Please select at least one of the choices.'
  },
  eligibility_survey: {
    question_required: 'This question is required.',
    radio_required: 'Please make a selection.',
    checkbox_required: 'Please select all that apply.'
  },
  form: {
    default: 'All fields are required, unless indicated.',
    all_fields_required: 'All fields are required.'
  },
  community_lookup: {
    patient_not_found: 'We are having trouble finding your results with this email address and zip code. ' +
      'Please try again or call our WATCHMAN Education Specialists at <a href="tel:+18442943320">1-844-294-3320</a> ' +
      'for help returning to your results.'
  },
  email_lookup_bar: {
    patient_not_found: 'We can’t find your results with this email address. ' +
      'Please try a different email address or retake the survey above.'
  },
  phone_modal_required: 'This field is required if you want a WATCHMAN Education Specialist to call you. Please enter a 10 digit number.',
  sms_terms_and_conditions_required: 'These SMS Terms & Conditions are required.',
  sms_phone_number_required: 'Mobile phone number is required to receive text messages',
  sms_consent_required: 'These SMS Terms & Conditions are required.'
}
