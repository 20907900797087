import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import * as directives from 'vuetify/lib/directives'
import * as components from 'vuetify/lib/components'
import '@mdi/font/css/materialdesignicons.css'
import colorStyleSheet from '@/styles/colorReference.module.scss'

const defaultLight = {
  light: true,
  colors: {
    primary1: colorStyleSheet.primary1,
    primary2: colorStyleSheet.primary2,
    primary3: colorStyleSheet.primary3,
    secondary1: colorStyleSheet.secondary1,
    secondary2: colorStyleSheet.secondary2,
    neutral1: colorStyleSheet.neutral1,
    neutral2: colorStyleSheet.neutral2,
    neutral3: colorStyleSheet.neutral3,
    neutral4: colorStyleSheet.neutral4,
    neutral5: colorStyleSheet.neutral5
  }
}

const display = {
  mobileBreakpoint: 'sm'
}

export default createVuetify({
  display,
  theme: {
    defaultTheme: 'defaultLight',
    themes: {
      defaultLight
    }
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi
    }
  },
  defaults: {
    VRadioGroup: {
      density: 'compact'
    },
    VCheckbox: {
      density: 'compact'
    },
    VInput: {
      density: 'compact'
    }
  },
  directives,
  components
})
