module.exports = {
  bsci_home: 'https://www.bostonscientific.com/',
  bsci_facebook: 'https://www.facebook.com/groups/519788196863315/',
  contact_us: 'https://www.watchman.com/en-us/contact-choice.html',
  copyright_notice: 'https://www.bostonscientific.com/en-US/copyright-notice.html',
  covid_19_release: 'https://news.bostonscientific.com/Response-COVID-19',
  covid_19_response: 'https://www.bostonscientific.com/en-US/boston-scientific-response-to-the-covid-19-pandemic.html',
  find_a_center: 'https://www.watchman.com/en-us/find-a-center.html',
  privacy_policy: 'https://www.bostonscientific.com/en-US/privacy-policy.html',
  register_implant: 'https://www.watchman.com/en-us/register.html',
  sms_terms_of_use: 'https://www.bostonscientific.com/en-US/text-message-terms-of-use.html',
  terms_of_use: 'https://www.bostonscientific.com/en-US/terms-of-use.html',
  watchman_home: 'https://www.watchman.com/',
  watchman_community_join: 'https://www.community.watchman.com/h/s/5opXn6TjDdS115D4WHgodY',
  watchman_community_login: 'https://www.community.watchman.com/hub',
  watchman_device_support: 'https://www.bostonscientific.com/en-US/patients-caregivers/device-support/watchman.html'
}
