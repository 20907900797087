const basePath = 'https://www.watchman.com/content/dam/watchman/spur/'
const icons = 'icons/'
const covid = 'covid/'
const photos = 'photos/'
const assets = 'assets/'
const campaign = 'campaign/'
const qualified = 'qualified-page/'
const survey = 'eligibility-survey/'
const unqualified = 'unqualified-page/'
const carouselVideo = 'carousel-video/'
const haveQuestionsMenu = 'flyout-menu/'
const patientStories = 'patient-stories/'
const carouselWritten = 'carousel-written/'
const ambassadorProfile = 'ambassador-profile/'
const considerationPhase = 'consideration-phase/'
const emailPhysicianPage = 'email-physician-page/'
const emailEducationSpecialistPage = 'email-education-specialist-page/'

module.exports = {

  /* Photos (used on the old Campaign pages) */
  dog_walker: basePath + photos + 'walker-dog-sm.png',
  man_computer: basePath + photos + 'man-computer.png',
  pt_ambassador: basePath + photos + 'pt-ambassador.jpg',
  doctor_patient: basePath + photos + 'doctor-patient.png',
  couple_on_bikes: basePath + photos + 'couple-bikes-sm.png',
  listening_woman: basePath + photos + 'listening-woman.png',
  couple_considering: basePath + photos + 'couple-considering.jpg',
  watchman_video_placeholder: basePath + photos + 'watchman-video.png',
  schedule_call_woman: basePath + photos + 'l6--appt/schedule-call-woman.png',

  /* Eligibility Survey Assets */
  kim_thumb_photo: basePath + assets + survey + 'photo_kim-sm.png',
  book_icon: basePath + assets + survey + 'icon_book.png',
  icon_grey_down_caret: basePath + assets + survey + 'icon_GrayDownCaret.png',
  permanent_implant_icon: basePath + assets + survey + 'icon_permanetimplant.png',
  procedure_less_1_hr: basePath + assets + survey + 'icon_procedureless1hour.png',
  hospital_stay_24_hrs: basePath + assets + survey + 'icon_24hourhospitalstay.png',
  minimally_invasive_icon: basePath + assets + survey + 'icon_minimallyinvasive.png',

  /* Icons */
  step_1_icon: basePath + assets + campaign + 'step-1.png',
  step_2_icon: basePath + assets + campaign + 'step-2.png',
  step_3_icon: basePath + assets + campaign + 'step-3.png',
  step_4_icon: basePath + assets + campaign + 'step-4.png',
  step_5_icon: basePath + assets + campaign + 'step-5.png',

  /* "Have Questions?" flyout menu */
  have_questions_menu: {
    icon_question_mark: basePath + assets + haveQuestionsMenu + 'icon-question-mark.png',
    icon_white_arrow_right: basePath + assets + haveQuestionsMenu + 'icon-arrow-white-right.png',
    icon_circle_close_blue: basePath + assets + haveQuestionsMenu + 'icon-circle-close-blue.png',
    icon_insurance: basePath + assets + haveQuestionsMenu + 'icon-insurance.png',
    icon_left_atrial: basePath + assets + haveQuestionsMenu + 'icon-LAA.png',
    icon_ambassadors: basePath + assets + haveQuestionsMenu + 'icon-ambassadors.png',
    icon_plan: basePath + assets + haveQuestionsMenu + 'icon-plan.png'
  },

  /* Info Landing page */
  photo_woman_jumping_in_lake: basePath + photos + 'woman-jumping-in-lake.jpg',
  photo_linda_and_husband: basePath + photos + 'linda-and-husband.png',

  /* Consideration Phase */
  clipboard: basePath + photos + considerationPhase + 'clipboard.png',
  find_watchman_center: basePath + photos + considerationPhase + 'find-watchman-center.png',
  hear_from_doctors_video_placeholder: basePath + photos + considerationPhase + 'hear-from-the-doctors-video-placeholder.jpg',

  /* Testimonials Written Carousel */
  ron_mobile: basePath + photos + carouselWritten + 'ron-mobile.png',
  ron_desktop: basePath + photos + carouselWritten + 'ron-desktop.png',

  john_mobile: basePath + photos + carouselWritten + 'john-mobile.png',
  john_desktop: basePath + photos + carouselWritten + 'john-desktop.png',

  barry_mobile: basePath + photos + carouselWritten + 'barry-mobile.png',
  barry_desktop: basePath + photos + carouselWritten + 'barry-desktop.png',

  /* Testimonials Video Carousel */
  bill: basePath + photos + carouselVideo + 'bill.png',
  billy: basePath + photos + carouselVideo + 'billy.png',
  ingrid: basePath + photos + carouselVideo + 'ingrid.png',
  postons: basePath + photos + carouselVideo + 'poston.png',
  marjorie: basePath + photos + carouselVideo + 'marjorie.png',

  /* Qualified */
  video_icon: basePath + assets + qualified + 'icon_video_on.png',
  patients_left_behind_thinners_150k_photo: basePath + assets + qualified + 'photo_150k_left_behind_thinners.png',
  patients_left_behind_thinners_300k: {
    url: basePath + assets + qualified + 'photo_300k_left_behind_thinners.png',
    desc: 'Over 300,000 people have left blood thinners behind with the WATCHMAN Implant'
  },
  man_climbing_ladder: {
    url: basePath + photos + 'man-climbing-ladder.png',
    desc: 'Man climbing ladder and looking up'
  },
  kim_education_specialist: basePath + assets + qualified + 'photo_kim_2x_copy.png',
  real_people_stories: {
    aj_photo: basePath + assets + qualified + 'photo_aj.png',
    mike_photo: basePath + assets + qualified + 'photo_mike.png',
    sean_photo: basePath + assets + qualified + 'photo_sean2.png',
    carol_photo: basePath + assets + qualified + 'photo_carol.png',
    liliane_photo: basePath + assets + qualified + 'photo_liliane.png',
    susan_photo: basePath + assets + qualified + 'photo_susan.png',
    jerry_ann_photo: basePath + assets + qualified + 'photo_jerry_ann.png',
    linda_photo: basePath + assets + qualified + 'photo_linda.png',
    marjorie_photo: basePath + assets + qualified + 'photo_marjorie.png'
  },

  /* Unqualified */
  email_on_icon: basePath + assets + unqualified + 'icon_email_on.png',
  email_off_icon: basePath + assets + unqualified + 'icon_email_off.png',
  phone_on_icon: basePath + assets + unqualified + 'icon_phone_on.png',
  phone_off_icon: basePath + assets + unqualified + 'icon_phone_off.png',

  /* Preview DDG Page */
  icon_search_white: basePath + assets + 'Icon_searchWhite.png',

  /* COVID-19 Banner and Page */
  heart_box: basePath + assets + covid + 'photo_heartbox.png',
  physicians: basePath + assets + covid + 'photo_physicians.png',
  sean_photo: basePath + assets + covid + 'photo_sean.png',
  pat_photo: basePath + assets + covid + 'photo_pat.png',

  /* Email Education Specialist page */
  sending_email_icon: basePath + assets + emailEducationSpecialistPage + 'icon_email.png',

  /* Send to Physician page */
  location_icon: basePath + assets + emailPhysicianPage + 'icon_location.png',

  /* General icons */
  icon_trash_blue: basePath + assets + 'icon_trash-blue.png',
  icon_orange_checkmark: basePath + assets + 'icon_checkmark-orange.png',
  icon_play_blue: basePath + assets + 'Icon_play-blue.png',
  icon_pause_blue_2x: basePath + assets + 'Icon_pause-blue_2x.png',
  icon_down_caret_for_nav: basePath + assets + icons + 'down-caret-blue.png',
  icon_down_caret_for_nav_active: basePath + assets + icons + 'down-caret-gray.png',
  icon_up_caret_for_nav: basePath + assets + icons + 'up-caret-navy.png',
  heart_illustration: basePath + assets + 'photo-heart-illustration.png',

  /* meter asset base path */
  meter_base_path: basePath + assets + 'meter/',

  // General site lifestyle photos
  woman_in_jean_jacket_orange_shirt: {
    url: basePath + photos + 'woman-in-jean-jacket-orange-shirt.jpg',
    desc: 'Woman in jean jacket and orange shirt'
  },
  man_standing_next_to_plant: {
    url: basePath + photos + 'man-standing-next-to-plant.png',
    desc: 'Smiling man standing next to a plant'
  },
  doctor_kanj: {
    url: basePath + photos + 'DrKanj.jpg',
    desc: 'Dr. Kanj, MD, Cardiologist'
  },
  speedy_grandson: {
    url: basePath + photos + 'speedy-grandson.jpg',
    desc: 'Woman running with her grandson'
  },
  woman_talking_to_doctor: {
    url: basePath + photos + 'woman-talking-to-doctor.jpg',
    desc: 'Woman talking to doctor'
  },
  man_climbing_treehouse: {
    url: basePath + photos + 'man-climbing-treehouse.jpg',
    desc: 'Man climbing treehouse ladder with female child'
  },
  womanWalkingWithSuperHero: {
    url: basePath + photos + 'woman-walking-with-superhero.png',
    desc: 'Woman walking with superhero'
  },
  man_jumping_off_dock: {
    url: basePath + photos + 'man-jumping-off-dock.jpg',
    desc: 'Man jumping off a dock'
  },
  woman_swimming: {
    url: basePath + photos + 'woman-swimming.jpg',
    desc: 'Woman swimming outdoors'
  },
  female_smiling: {
    url: basePath + photos + 'woman-smiling.jpg',
    desc: 'Woman smiling outdoors'
  },
  bill_headshot: {
    url: basePath + assets + patientStories + 'photo_bill_cropped_2.png',
    desc: 'Bill smiling'
  },
  barry_headshot_rounded: {
    url: basePath + photos + 'barry-rounded.jpg',
    desc: 'Smiling headshot of Barry'
  },
  linda_carousel: {
    url: basePath + photos + 'linda-carousel.jpg',
    desc: 'Linda smiling'
  },
  fred_carousel: {
    url: basePath + photos + 'fred-carousel.jpg',
    desc: 'Fred smiling'
  },
  woman_on_laptop: {
    url: basePath + photos + 'woman-on-laptop.png',
    desc: 'Woman using a laptop talking to doctor'
  },

  /* Patient Ambassadors images */
  patient_ambassadors: {
    contact_by: {
      icon_phone_grey: basePath + assets + 'icon_phone-grey.png',
      icon_email_grey: basePath + assets + 'icon_email-grey.png',
      icon_video_grey: basePath + assets + 'icon_video-grey.png'
    },
    icon_clock_black: basePath + assets + 'icon_clock-black.png',
    icon_location_black: basePath + assets + 'icon_location-black.png',
    profile: {
      photo_coming_soon: basePath + photos + ambassadorProfile + 'photo-coming-soon.jpg'
    }
  },

  /* Video thumbnails */
  watchman_video_thumbnail_with_play_button: basePath + assets + 'watchman-video-thumbnail-with-play-button-2x.png',
  vimeo_different_from_blood_thinners: basePath + assets + 'vimeo-different-from-blood-thinners.png',
  vimeo_afib_stroke_risk: basePath + assets + 'vimeo-afib-stroke-risk.png',
  vimeo_procedure: basePath + assets + 'vimeo-procedure.png',
  vimeo_talking_with_doctor: basePath + assets + 'vimeo-talking-with-doctor.png',
  vimeo_why_choose: basePath + assets + 'vimeo-why-choose.png',
  vimeo_manage_afib_stroke_risk: basePath + assets + 'vimeo-manage-afib-strok-risk.png',
  vimeo_how_does_the_watchman_implant_work: basePath + assets + 'vimeo-how-does-the-watchman-implant-work.png',
  vimeo_what_is_afib: basePath + assets + 'vimeo-what-is-afib.png',

  /* What to expect */
  what_to_expect: {
    day_of_procedure_doris: basePath + photos + 'what-to-expect-day-of-procedure-doris.jpg'
  },
  ddgPages: basePath + assets + 'ddgPages.png'
}
