/* From: https://www.mockaroo.com/f058fad0 */
const physicians = [
  {
    'distance': 0.0,
    'id': '0031N00001IIIK4QAP',
    'firstName': 'Amir',
    'lastName': 'Azeem',
    'account': {
      'accountNumber': '18637',
      'mailingName': 'MEMORIAL HERMANN SOUTHWEST HOSPITAL'
    },
    'mailingAddress': {
      'street': '3521 Town Center Blvd S Ste B',
      'city': 'Sugar Land',
      'state': 'TX',
      'postalCode': '10001',
      'country': 'US',
      'geocodeAccuracy': null,
      'latitude': null,
      'longitude': null
    },
    'email': 'katie.howe@bsci.com',
    'phone': '(713)772-1212',
    'speciality': 'Electrophysiology',
    'userID': '13022',
    'successfulImplantCount': 23.0,
    'referralCount': 0.0,
    'mailingPostalCode': '10001',
    'implanterSegmentation': 'Bronze',
    'acceptanceRank': 'Non-Referrer',
    'isWatchmanImplanter': true,
    'isSpurCoordinatorPortalPilot': false
  },
  {
    'distance': 0.0,
    'id': '003o000000XogdWAAR',
    'firstName': 'John',
    'lastName': 'Chiu',
    'account': {
      'accountNumber': '23775',
      'mailingName': 'ALTA BATES SUMMIT MEDICAL CENTER'
    },
    'mailingAddress': {
      'street': '20126 Stanton Ave Ste 100',
      'city': 'Castro Valley',
      'state': 'CA',
      'postalCode': '10001',
      'country': 'US',
      'geocodeAccuracy': null,
      'latitude': null,
      'longitude': null
    },
    'email': 'katie.howe@bsci.com',
    'phone': '(510)537-3556',
    'speciality': 'Interventional Cardiology',
    'title': 'Physician',
    'userID': '650',
    'successfulImplantCount': 35.0,
    'referralCount': 5.0,
    'mailingPostalCode': '10001',
    'implanterSegmentation': 'Silver',
    'acceptanceRank': 'Non-Referrer',
    'isWatchmanImplanter': true,
    'isSpurCoordinatorPortalPilot': false
  },
  {
    'distance': 0.0,
    'id': '003o000000XogarAAB',
    'firstName': 'David',
    'lastName': 'Delurgio',
    'account': {
      'accountNumber': '298491',
      'mailingName': 'EMORY SAINT JOSEPHS HOSPITAL'
    },
    'mailingAddress': {
      'street': '5671 Peachtree Dunwoody Rd Ste 300',
      'city': 'Atlanta',
      'state': 'GA',
      'postalCode': '10001',
      'country': 'US',
      'geocodeAccuracy': null,
      'latitude': null,
      'longitude': null
    },
    'email': 'katie.howe@bsci.com',
    'phone': '(404)778-6070',
    'speciality': 'Electrophysiology',
    'title': 'Clinician',
    'userID': '508',
    'successfulImplantCount': 141.0,
    'referralCount': 11.0,
    'endoNPI': '1144230509',
    'mailingPostalCode': '10001',
    'implanterSegmentation': 'Diamond',
    'isWatchmanImplanter': true,
    'isSpurCoordinatorPortalPilot': false
  },
  {
    'distance': 0.0,
    'id': '003o000000XogVwAAJ',
    'firstName': 'Vishal',
    'lastName': 'Gupta',
    'account': {
      'accountNumber': '47908',
      'mailingName': 'HEART INST OF BORGESS *'
    },
    'mailingAddress': {
      'street': '1717 SHAFFER ST STE 232',
      'city': 'KALAMAZOO',
      'state': 'MI',
      'postalCode': '10001',
      'country': 'US',
      'geocodeAccuracy': null,
      'latitude': null,
      'longitude': null
    },
    'email': 'katie.howe@bsci.com',
    'phone': '269-226-5050',
    'speciality': 'Interventional Cardiology',
    'title': 'MD',
    'userID': '4123',
    'successfulImplantCount': 34.0,
    'referralCount': 17.0,
    'endoNPI': '1255449179',
    'mailingPostalCode': '10001',
    'implanterSegmentation': 'Bronze',
    'acceptanceRank': 'Non-Referrer',
    'isWatchmanImplanter': true,
    'isSpurCoordinatorPortalPilot': false
  },
  {
    'distance': 0.0,
    'id': '0031N00001ESSbSQAX',
    'firstName': 'Troy',
    'lastName': 'Hounshell',
    'account': {
      'accountNumber': '18785',
      'mailingName': 'MERCYONE DES MOINES'
    },
    'mailingAddress': {
      'street': '5880 University Ave',
      'city': 'West Des Moines',
      'state': 'IA',
      'postalCode': '10001',
      'country': 'US',
      'geocodeAccuracy': null,
      'latitude': null,
      'longitude': null
    },
    'email': 'katie.howe@bsci.com',
    'phone': '(515)633-3600',
    'speciality': 'Electrophysiology',
    'title': 'DO',
    'userID': '6804',
    'successfulImplantCount': 82.0,
    'referralCount': 30.0,
    'endoNPI': '1730387473',
    'mailingPostalCode': '10001',
    'implanterSegmentation': 'Diamond',
    'isWatchmanImplanter': true,
    'isSpurCoordinatorPortalPilot': false
  },
  {
    'distance': 0.0,
    'id': '003o000001EBAGhAAP',
    'firstName': 'Robert',
    'lastName': 'Schaller',
    'account': {
      'accountNumber': '16043',
      'mailingName': 'HOSPITAL OF THE UNIVERSITY OF PENN'
    },
    'mailingAddress': {
      'street': '3400 CIVIC CENTER BLVD',
      'city': 'PHILADELPHIA',
      'state': 'PA',
      'postalCode': '10001',
      'country': 'US',
      'geocodeAccuracy': null,
      'latitude': null,
      'longitude': null
    },
    'email': 'katie.howe@bsci.com',
    'phone': '(215) 349-8027',
    'speciality': 'Electrophysiology',
    'userID': '8814',
    'successfulImplantCount': 5.0,
    'referralCount': 1.0,
    'endoNPI': '1083806780',
    'mailingPostalCode': '10001',
    'implanterSegmentation': 'Gold',
    'acceptanceRank': 'Non-Referrer',
    'isWatchmanImplanter': false,
    'isSpurCoordinatorPortalPilot': false
  },
  {
    'distance': 0.0,
    'id': '003o000000upmQvAAI',
    'firstName': 'Mark',
    'lastName': 'Seifert',
    'account': {
      'accountNumber': '16741',
      'mailingName': 'HONORHEALTH JOHN C LINCOLN MEDICAL'
    },
    'mailingAddress': {
      'street': '9250 N 3rd St Ste 2000',
      'city': 'Phoenix',
      'state': 'AZ',
      'postalCode': '10001',
      'country': 'US',
      'geocodeAccuracy': null,
      'latitude': null,
      'longitude': null
    },
    'email': 'katie.howe@bsci.com',
    'phone': '(602)861-1168',
    'speciality': 'Electrophysiology',
    'userID': '11005',
    'successfulImplantCount': 11.0,
    'referralCount': 1.0,
    'endoNPI': '1942201694',
    'mailingPostalCode': '10001',
    'implanterSegmentation': 'Bronze',
    'acceptanceRank': 'RSPT',
    'isWatchmanImplanter': true,
    'isSpurCoordinatorPortalPilot': false
  },
  {
    'distance': 0.0,
    'id': '003o000000XogYDAAZ',
    'firstName': 'Jonathan',
    'lastName': 'Silver',
    'account': {
      'accountNumber': '17247',
      'mailingName': 'LAHEY HOSPITAL & MEDICAL CENTER'
    },
    'mailingAddress': {
      'street': '41 Mall Rd',
      'city': 'Burlington',
      'state': 'MA',
      'postalCode': '10001',
      'country': 'US',
      'geocodeAccuracy': null,
      'latitude': null,
      'longitude': null
    },
    'email': 'katie.howe@bsci.com',
    'phone': '(781)744-8863',
    'speciality': 'Electrophysiology',
    'title': 'Attending',
    'userID': '4007',
    'successfulImplantCount': 50.0,
    'referralCount': 6.0,
    'endoNPI': '1033153176',
    'mailingPostalCode': '10001',
    'implanterSegmentation': 'Diamond',
    'isWatchmanImplanter': true,
    'isSpurCoordinatorPortalPilot': false
  },
  {
    'distance': 0.0,
    'id': '0031N00001M9fY5QAJ',
    'firstName': 'Peter',
    'lastName': 'Weiss',
    'account': {
      'accountNumber': '15969',
      'mailingName': 'SALT LAKE REGIONAL MEDICAL CENTER'
    },
    'mailingAddress': {
      'street': 'Intermountain Heart Rhythm Spec 5169 S Cottonwood St Bldg 2 Ste 510',
      'city': 'Salt Lake City',
      'state': 'UT',
      'postalCode': '10001',
      'country': 'US',
      'geocodeAccuracy': null,
      'latitude': null,
      'longitude': null
    },
    'email': 'katie.howe@bsci.com',
    'phone': '(801)507-3513',
    'speciality': 'Electrophysiology',
    'title': 'MD',
    'userID': '612',
    'successfulImplantCount': 86.0,
    'referralCount': 14.0,
    'mailingPostalCode': '10001',
    'implanterSegmentation': 'Gold',
    'acceptanceRank': 'Non-Referrer',
    'isWatchmanImplanter': true,
    'isSpurCoordinatorPortalPilot': false
  },
  {
    'distance': 0.0,
    'id': '003o000000XogZZAAZ',
    'firstName': 'Ian',
    'lastName': 'Woollett',
    'account': {
      'accountNumber': '29906',
      'mailingName': 'SENTARA NORFOLK GENERAL HOSPITAL'
    },
    'mailingAddress': {
      'street': '612 Kingsborough Sq Ste 100',
      'city': 'Chesapeake',
      'state': 'VA',
      'postalCode': '10001',
      'country': 'US',
      'geocodeAccuracy': null,
      'latitude': null,
      'longitude': null
    },
    'email': 'katie.howe@bsci.com',
    'phone': '(757)547-9294',
    'speciality': 'Electrophysiology',
    'userID': '466',
    'successfulImplantCount': 80.0,
    'referralCount': 3.0,
    'endoNPI': '1528012234',
    'mailingPostalCode': '10001',
    'implanterSegmentation': 'Diamond',
    'acceptanceRank': 'Non-Referrer',
    'isWatchmanImplanter': true,
    'isSpurCoordinatorPortalPilot': false
  },
  {
    'distance': 6.8,
    'id': '003o000001BjpOLAAZ',
    'firstName': 'Cheng Han',
    'lastName': 'Chen',
    'account': {
      'accountNumber': '21819',
      'mailingName': 'SADDLEBACK MEMORIAL MEDICAL CENTER'
    },
    'mailingAddress': {
      'street': '161 FORT WASHINGTON AVE FL 6',
      'city': 'NEW YORK',
      'state': 'NY',
      'postalCode': '10032',
      'country': 'US',
      'geocodeAccuracy': null,
      'latitude': null,
      'longitude': null
    },
    'phone': '310-880-4517',
    'speciality': 'Interventional Cardiology',
    'userID': '9422',
    'successfulImplantCount': 14.0,
    'referralCount': 1.0,
    'endoNPI': '1215181722',
    'mailingPostalCode': '10032',
    'implanterSegmentation': 'Bronze',
    'acceptanceRank': 'Non-Referrer',
    'isWatchmanImplanter': true,
    'isSpurCoordinatorPortalPilot': false
  }
]

module.exports = physicians
