import localAssets from '@/utils/local-assets'
import externalLinks from '@/utils/external.links.config'
const damImages = require('../utils/dam.images.repo.config')

let localRouteNames = {
  results: 'results',
  referral: 'referral',
  questionnaire: 'questionnaire',
  emailPreview: 'emailPreview',
  emailFriends: 'email-friends',
  emailProvider: 'emailProvider',
  registerWatchman: 'register-watchman',
  emailFriendsBrochure: 'email-friends-brochure',
  emailEducationSpecialistInternal: 'email-education-specialist-internal',
  emailEducationSpecialistExternal: 'email-education-specialist-external',
  emailEducationSpecialistConfirmationInternal: 'emailEducationSpecialistConfirmation',
  emailEducationSpecialistConfirmationExternal: 'emailEducationSpecialistExternalConfirmation',
  isItSafe: 'is-it-safe',
  patientStories: 'patient-stories',
  howDoesItWork: 'how-does-it-work',
  whyChooseWatchman: 'why-choose-watchman',
  previewGuide: 'preview-guide',
  previewBrochure: 'preview-brochure',
  appointmentPreparation: 'appointment-preparation',
  ambassadorProfile: 'patient-stories-ambassador-profile',
  ambassadorLibrary: 'patient-stories-ambassadors-library',
  ambassadorSendAnEmail: 'patient-stories-ambassador-send-an-email',
  ambassadorMeetingChoice: 'patient-stories-ambassador-meeting-choice',
  ambassadorRequestAPhoneCall: 'patient-stories-ambassador-request-a-phone-call',
  videoLibrary: 'video-library',
  becomeAnAmbassador: 'become-an-ambassador',
  whatToExpect: 'what-to-expect',
  talkToDoctor: 'talk-to-doctor',
  viewYourRisks: 'view-your-risks',
  understandingCost: 'understanding-cost',
  questionnaireResubmission: 'questionnaire-resubmission'
}

export default {
  call_center_times: 'Monday - Friday, 8:00 am to 5:00 pm Central time',
  people_implanted_worldwide: '300,000+ people implanted worldwide',
  people_left_behind_thinners: 'Over 300,000 people have left blood thinners behind with the WATCHMAN Implant',
  privacy_copy: {
    clickSubmit: 'By clicking Submit, I consent to Boston Scientific using all information I enter onto this form to ' +
      'contact me with information regarding its products and services, inform me regarding various news, events & ' +
      'research studies and allow Boston Scientific to use this information for additional purposes identified in its ',
    clickSubmitMainSurvey: 'By submitting this form, I consent to Boston Scientific using all information I enter ' +
      'onto this form to contact me with information regarding its products and services, inform me regarding ' +
      'various news, events and research studies and allow Boston Scientific to use this information for additional ' +
      'purposes identified in its Privacy Policy and',
    clickSubmitNoBaseLinks: 'By submitting this form, I consent to Boston Scientific using all information I enter ' +
      'onto this form to contact me with information regarding its products and services, inform me regarding ' +
      'various news, events and research studies and allow Boston Scientific to use this information for additional ' +
      'purposes identified in its',
    privacyPolicy: 'Privacy Policy',
    termsOfUse: 'Terms of Use'
  },
  phone_number: {
    register_watchman: '1-855-637-9428',
    education_specialist: '1-844-294-3320',
    recently_submitted_modal: '1-855-893-2951',
    patient_ambassador_community: '1-844-355-9114',
    patient_education_specialist: '1-844-324-5812'
  },
  why_copy: 'Why do you need this?',
  ambassador_patient_disclaimer: 'The WATCHMAN Ambassador Community is a resource for people considering the WATCHMAN Implant,' +
    ' but it is not a replacement for conversations with your doctor. Please keep in mind Ambassadors are not medical professionals' +
    ' and therefore will not offer medical advice. Please do not share your protected health information or other sensitive information' +
    ' in your message or conversation with an Ambassador. Every experience with the WATCHMAN Implant is unique, so it is important to' +
    ' discuss with your doctor whether the WATCHMAN Implant is right for you. Do not make changes to your treatment plan' +
    ' unless instructed by your doctor.',
  ambassador_community_disclaimer: 'The WATCHMAN Ambassador Community is a resource for people considering the WATCHMAN' +
    ' Implant, but it is not a replacement for conversations with your doctor. Please keep in mind Ambassadors are not' +
    ' medical professionals and therefore will not offer medical advice. Please do not share your protected health' +
    ' information or other sensitive information in your message or conversation with an Ambassador. Every experience' +
    ' with the WATCHMAN Implant is unique, so it is important to discuss with your doctor whether the WATCHMAN Implant' +
    ' is right for you. Do not make changes to your treatment plan unless instructed by your doctor.',
  ambassador_page: {
    didYouKnow: 'Did you know you have the opportunity to connect with a WATCHMAN Ambassador?',
    disclaimer: 'Keep in mind Patient Ambassadors are not medical professionals and cannot offer medical ' +
      'advice. Conversations with Ambassadors are not a replacement for conversations with your doctor.',
    introHeaderCopy: 'Patient Ambassador Community',
    introHeaderSubCopy: 'Patient Ambassadors are a community of people that have received a WATCHMAN ' +
      'Implant and have volunteered to share their personal experiences.',
    subHeaderCopy: 'Fill out the form below and a Patient Education Specialist will answer your questions ' +
      'about the WATCHMAN Implant and help match you with an Ambassador.',
    confirmationHeader: 'Thank you for requesting to speak with a Patient Ambassador',
    confirmationMessage: 'A WATCHMAN Education Specialist will be in contact with you to confirm details once we ' +
      'have an Ambassador match.<br/>If you have any questions, call our team today:',
    biography: {
      questions: {
        whyDidIChoose: 'Why did I choose the WATCHMAN Implant for the stroke protection?',
        whatWasMyExperience: 'What was my experience with the WATCHMAN Implant procedure and recovery?',
        whatWouldITellSomeone: 'What would I tell someone considering the WATCHMAN Implant?'
      }
    },
    fourBoxes: {
      //
    },
    connectionRequest: {
      phone: 'Request a phone call',
      email: 'Send a message'
    }
  },
  ambassadors_call_fail: {
    trouble_displaying: 'It seems we are having trouble displaying our WATCHMAN Ambassadors.',
    check_back: 'Please check back soon.'
  },
  ambassadors_no_filter_results: {
    no_results: 'Sorry, no results were found.',
    change_filters: 'Try changing or removing some of the filters.'
  },
  colle_mcvoy: {
    bloodThinnerHeader: 'Does being on a blood thinner keep you from living life your way?',
    bloodThinnersSubText: 'Use your personal discussion guide to talk with your doctor about these challenges.'
  },
  eligibility_survey: {
    did_you_know: 'Did you know over 300,000+ people have left blood thinners behind with the WATCHMAN Implant?',
    tooltip: {
      phone: {
        header: {
          copy: 'Hi! I\'m Kim, a WATCHMAN Education Specialist',
          image: damImages.kim_thumb_photo
        },
        body: 'If you provide your phone number, we can connect you with a WATCHMAN Education Specialist like ' +
          'me to discuss your results and any questions about the WATCHMAN Implant.'
      },
      dob: {
        body: 'As we age, the risk of stroke due to AFib increases. Your age is an important factor to consider as ' +
          'you discuss treatment options with your doctor.'
      }
    }
  },
  pre_survey_landing: {
    survey_question_one_component: {
      title: 'Take our short quiz to see if the WATCHMAN Implant is right for you. You will receive your personalized doctor discussion guide, gain access to connect with a WATCHMAN Ambassador, and much more.',
      question: 'Do you have atrial fibrillation (AFib) not caused by a heart valve problem?',
      button_1_text: 'Yes',
      button_2_text: 'No'
    }
  },
  email_education_specialist_page: {
    introHeaderCopy: 'Email a WATCHMAN Education Specialist',
    introHeaderSubCopy: 'They are trained professionals with healthcare experience available to answer questions ' +
      'you may have about the WATCHMAN Implant.',
    subHeaderCopy: 'Enter the information below and a Patient Education Specialist will get back to you.',
    tooltip: {
      phone: {
        body: 'Providing your phone number will allow a Patient Education Specialist to call you to discuss ' +
          'questions you have about the WATCHMAN Implant.'
      }
    }
  },
  email_education_specialist: {
    confirmationHeader: 'Thank you',
    confirmationMessage: 'A Patient Education Specialist will get back to you soon.'
  },
  email_provider_page: {
    introHeaderCopy: 'Share your WATCHMAN discussion guide with your doctor.',
    confirmationHeader: 'Thank you for sharing your discussion guide.',
    confirmationMessage: 'A copy of your guide was sent to '
  },
  email_friends_family_page: {
    ddg: {
      introHeaderCopy: 'Share your WATCHMAN discussion guide with your family and friends.',
      confirmationHeader: 'Thank you for sharing your discussion guide.',
      confirmationMessage: 'A copy of your guide was sent to ',
      shareType: 'discussion guide'
    },
    brochure: {
      introHeaderCopy: 'Share the WATCHMAN Implant brochure with your family and friends.',
      confirmationHeader: 'Thank you for sharing the brochure.',
      confirmationMessage: 'A copy of the brochure was sent to ',
      shareType: 'brochure'
    }
  },
  qualified_page: {
    intro: {
      header_qualified: 'The WATCHMAN Implant may be right for you. Talk to your doctor about reducing your stroke risk without lifelong blood thinners.',
      header_other_stages: 'Continue exploring life without blood thinners with the WATCHMAN Implant.',
      expandable_box_content_qualified: {
        primary_text: 'See why the WATCHMAN Implant may be right for you',
        expanded_text: 'Based on your survey results:',
        reasons: {
          reason1: 'You have been diagnosed with atrial fibrillation (AFib) not caused by a heart valve problem.',
          reason2: 'You have been prescribed a blood thinner to reduce your AFib related stroke risk.',
          reason3: 'You have reason to seek an alternative to blood thinners.'
        }
      },
      expandable_box_content_other_stages: {
        primary_text: 'Know the steps to the WATCHMAN Implant:',
        steps: {
          step1: 'Learn about the WATCHMAN Implant',
          step2: 'Talk with your doctor',
          step3: 'Prepare for your procedure',
          step4: 'Living with the WATCHMAN Implant'
        }
      },
      unauthenticated_line1: 'The WATCHMAN Implant',
      unauthenticated_line2: 'could be right for you if',
      reasons: {
        reason1: 'You have non-valvular atrial fibrillation (AFib)',
        reason2: 'You have an elevated stroke risk',
        reason3: 'You have been prescribed a blood thinner',
        reason4: 'You spend less time on hobbies, social engagements, or work due to taking blood thinners'
      }
    },
    stepsToTheWmImplant: {
      headerData: {
        headerIntroText: 'Continue on your path to freedom from lifelong blood thinners',
        headerText: 'Steps to the WATCHMAN Implant'
      },
      iconStackBlock: [
        {
          header: 'Learn about the WATCHMAN Implant',
          copy: 'The WATCHMAN Implant is a safe, minimally invasive, one-time implant that reduces your stroke risk without the need for lifelong blood thinners.',
          subscript: '*'
        },
        {
          header: 'Talk with your doctor',
          copy: 'Schedule an appointment with your doctor to discuss if the WATCHMAN Implant is right for you. You may be referred to see a WATCHMAN implanting specialist. '
        },
        {
          header: 'Prepare for your procedure',
          copy: 'The procedure has a 99% success rate and a low 0.5% rate of major complications.',
          subscript: '1**',
          copyAfterSubscript: 'A typical hospital stay is one day or less.'
        },
        {
          header: 'Living with the WATCHMAN Implant',
          copy: 'After the WATCHMAN Implant procedure, life can be a lot less restricted. 96% of people are able to stop their blood thinner 45 days after the procedure.',
          subscript: '1'
        }
      ],
      manOnLadderImage: {
        url: damImages.man_climbing_ladder.url,
        desc: damImages.man_climbing_ladder.desc
      }
    },
    why_sub_header: 'The WATCHMAN Implant reduces stroke risk and bleeding concerns. It is placed in the heart during a minimally invasive procedure.',
    in_page_nav_links: {
      how_does_it_work: {
        title: 'How does it work?',
        routeName: localRouteNames.howDoesItWork
      },
      why_choose: {
        title: 'Why choose the WATCHMAN Implant?',
        routeName: localRouteNames.whyChooseWatchman
      },
      is_it_safe: {
        title: 'Is it safe?',
        routeName: localRouteNames.isItSafe
      },
      patient_stories: {
        title: 'Patient Stories',
        routeName: localRouteNames.patientStories
      },
      what_to_expect: {
        title: 'What to expect with the procedure',
        routeName: localRouteNames.whatToExpect
      }
    }
  },
  unqualified_page: {
    intro: {
      header: {
        line1: 'The WATCHMAN Implant',
        line2: 'may not be right for you at this time.'
      },
      content: 'Your doctor understands your health situation best and it\'s important to review this discussion ' +
        'guide with them to confirm your results or discuss all of your stroke risk reduction options.'
    },
    eligibility_can_change: {
      reasons: {
        hasAfib: 'atrial fibrillation <em>(AFib)</em> not caused by a heart valve problem <em>(also known as ' +
          'non-valvular AFib)</em>',
        hasBloodThinners: 'been prescribed a blood thinner due to your AFib <em>(even if you\'re no longer ' +
          'taking it)</em>',
        hasQualifyingChadScore: 'an elevated stroke risk as measured by a CHA₂DS₂-VASc ' +
          'score of 3 or greater <em>(this calculation is based on age, gender and other health factors that affect ' +
          'your stroke risk)</em>, keep in mind your stroke risk may increase as you turn 65 and 75.',
        hasAnyAppropriateRationale: 'a need for an alternative to blood thinners because of side effects or issues ' +
          'with bleeding, a lifestyle, occupation or condition that puts you at risk for bleeding, or difficulties ' +
          'taking your blood thinner as prescribed.'
      }
    }
  },
  join_watchman_online_community_component: {
    title: 'Join the WATCHMAN online community',
    bodyText: 'Connect with others learning about the WATCHMAN Implant & Ambassadors who have the WATCHMAN Implant.',
    footerText: 'Already a member of the community?'
  },
  watchman_education_team_component: {
    header: 'WATCHMAN Education Team',
    subheader: 'Meet Kim, a WATCHMAN Education Specialist',
    unqualified: {
      title: 'Our Education Specialists are here to answer questions about your survey results or ' +
        'additional questions you may have about the WATCHMAN Implant. They can help you change your survey ' +
        'responses if you feel you entered something in error.'
    },
    qualified: {
      title: 'Our trained professionals with healthcare experience are available to answer your questions.'
    }
  },
  doctor_discussion_guide_component: {
    unauthenticated: {
      rationale: {
        copy: 'Unlock your personalized discussion guide to help start a conversation with your doctor about the WATCHMAN Implant'
      }
    },
    qualified: {
      rationale: 'We\'ve put together a discussion guide to make it easy to talk to your doctor at your next appointment.',
      rationale_with_strong: {
        strong: 'We\'ve put together a discussion guide',
        normal: ' to make it easy to talk to your doctor at your next appointment.'
      }
    },
    unqualified: {
      rationale: 'In order to be eligible for the WATCHMAN Implant you need to have the following:',
      bullets: {
        b1: 'Atrial fibrillation (AFib) not caused by a heart valve problem',
        b2: 'Have an elevated stroke risk',
        b3: 'Taking a blood thinner or have been prescribed a blood thinner',
        b4: 'Have a meaningful reason to find an alternative to blood thinners'
      },
      summary: 'Even though you might not be eligible at this time, bring your survey results into your doctor to ' +
        'discuss what your options might be.'
    }
  },
  why_watchman: {
    header: {
      part1: 'One Time.',
      part2: 'For a Lifetime.'
    },
    count: {
      number: '300,000+',
      postTextTop: 'Lives Changed',
      postTextBottom: 'and Counting'
    },
    what_is_watchman_header: 'What is the WATCHMAN Implant?',
    what_is_watchman_image: localAssets.graphicQuarterWithWm,
    what_is_watchman_info: 'It\'s a small, flexible implant about the size of a quarter made from very light, compact ' +
      'materials used in many other medical implants. It is placed into your heart during a minimally invasive ' +
      'procedure and never needs to be replaced.'
  },
  register_watchman_page: {
    form: {
      header: 'Register your WATCHMAN Implant Card',
      subHeaderCopy: 'Please enter the information below to register your WATCHMAN Implant card. You should have ' +
        'received your card on the day of your WATCHMAN Implant. If you did not receive a card or need a new one, ' +
        'please call '
    },
    confirmation: {
      header: 'Thank you for registering your WATCHMAN Implant Card.'
    }
  },
  data_layer: {
    quiz_title: 'SPUR Eligibility Survey'
  },
  email_lookup_bar_component: {
    resubmit_prompt: 'Have you already taken our survey?',
    enter_email_subtext: 'Enter your email to return to your results.',
    email_lookup_success: 'We sent you an email at ',
    email_lookup_success_subtext: 'You will find a link in that email to return to your results.  If you don’t see ' +
      'it, look in your junk or spam folders.'
  },
  phone_modal_consent: {
    yes: 'Yes, I would like to receive text messages.',
    no: 'Not at this time.',
    phone_input_label: 'Enter your phone number',
    required_consent_message: 'These SMS Terms & Conditions are required.',
    heading: 'This number is a mobile number and I would like to receive text messages from WATCHMAN.'
  },
  no_phone_modal: {
    phone_input_label: 'Enter your phone number'
  },
  previewDiscussionGuide: {
    sections: {
      yourEligibilityResults: {
        title: 'Your eligibility results',
        summary: '<strong>Yes!</strong> Based on your survey results, the WATCHMAN Implant may be right for you.',
        content: {
          topLevel: {
            aFib: {
              copy: 'You have been diagnosed with atrial fibrillation (AFib) not caused by a heart valve problem.'
            },
            strokeRisk: {
              copy: 'You have been prescribed a blood thinner to reduce your AFib related stroke risk.'
            },
            healthCondition: {
              copy: 'You have a health condition that increases your stroke risk, and possibly your bleeding risk, too.',
              subcopy: 'Examples: hypertension (elevated blood pressure), diabetes, congestive heart failure, stroke, vascular disease'
            },
            seekAlternative: {
              copy: 'You have reason to seek an alternative to blood thinners.'
            }
          },
          rationales: {
            hasAbnormalLiverBleedRisk: {
              copy: 'You have abnormal liver function that increases your risk of bleeding.'
            },
            hasAbnormalKidneyBleedRisk: {
              copy: 'You have abnormal kidney (renal) function that increases your risk of bleeding.'
            },
            hasAntiPlateletTherapyBleedRisk: {
              copy: 'You\'re on long-term dual anti-platelet therapy (DAPT) that increases your risk of bleeding.',
              subcopy: 'Examples: clopidogrel, prasugrel, ticagrelor, Plavix®'
            },
            hasNeedForAspirinBleedRisk: {
              copy: 'You have a need for prolonged use of aspirin or NSAIDs: medications that increase your risk of bleeding. ',
              subcopy: 'Examples: ibuprofen, Aleve'
            },
            hasOtherHealthConditionsForBleedRisk: {
              copy: 'You have other health conditions that may increase your risk of bleeding.',
              subcopy: 'Examples: cancer, dementia, Alzheimer\'s'
            },
            hasFallRisk: {
              copy: 'You have experienced decreased stability or mobility that increase your risk of falling.',
              subcopy: 'Examples: dizziness, lightheadedness, difficulty climbing stairs'
            },
            hasJobThatIncreaseBleedOrFallRisk: {
              copy: 'Your job requires you to perform duties that increase your risk of bleeding or falling.',
              subcopy: 'Examples: lifting, using power tools, climbing ladders, cutting'
            },
            hasHobbiesThatIncreaseBleedOrFallRisk: {
              copy: 'Your hobbies or daily activities increase your risk of bleeding or falling.',
              subcopy: 'Examples: biking, running, gardening, fishing, housework'
            },
            hasMadeLifestyleTradeoffs: {
              copy: 'You have made lifestyle tradeoffs due to your blood thinners.',
              subcopy: 'Examples: reduced time spent on hobbies, work, social engagements, or decreased quality of life'
            },
            hasExperiencedMajorBleed: {
              copy: 'You have experienced major bleeding.',
              subcopy: 'Examples: GI bleeding, bleeding in the brain or head'
            },
            hasExperiencedMinorBleed: {
              copy: 'You have experienced minor bleeding.',
              subcopy: 'Example: nose bleeds'
            },
            hasTroubleRememberingBloodThinner: {
              copy: 'You have a hard time remembering to take your blood thinner.'
            },
            hasTroubleStayingWithinInr: {
              copy: 'You have a hard time staying within clotting range, or INR.'
            },
            hasTroubleKeepingInrAppointments: {
              copy: 'You have a hard time keeping your regular INR blood testing appointments.'
            },
            hasExperiencedBloodThinnerSideEffects: {
              copy: 'You have experienced side effects from your blood thinner.',
              subcopy: 'Examples: nausea, loss of appetite, abdominal pain, dizziness'
            },
            hasBloodThinnerMajorIssues: {
              copy: 'You have experienced other major issues while taking a blood thinner.'
            }
          }
        }
      },
      understandYourRisks: {
        title: 'Understand your risks',
        summary: 'See your stroke risk and bleed risk scores.',
        text: 'Our survey calculated your stroke risk and bleed risk. Your stroke risk is calculated by your CHA₂DS₂-VASc' +
          ' score, and bleed risk is calculated by your HAS-BLED score. These scores, along with other individual factors,' +
          ' are commonly used by doctors to evaluate stroke risk and bleed risk in people with AFib to help guide treatment' +
          ' options.'
      },
      talkToYourDoctor: {
        title: 'Talk to your doctor',
        summary: 'Prepare for a conversation with this personalized guide.',
        text: 'Share your survey results and examples of challenges you\'ve experienced on blood thinner medication.' +
          ' Here are some additional topics you may want to discuss with your doctor.',
        topicList: [
          'I want to know if I\'m a candidate for the WATCHMAN Implant, and why.',
          'I\'m not happy with the side effects or bruising I\'m experiencing from my blood thinner.',
          'I sometimes forget to take my blood thinner.',
          'I sometimes skip or split my recommended dose of blood thinner.',
          'I\'m concerned with managing my blood thinner costs.',
          'I have decreased stability that may increase my risk of falling.',
          'I\'ve stopped eating certain foods due to interactions with my blood thinner medication.',
          'My job requires me to perform duties that put me at risk of bleeding or falling.',
          'I\'ve stopped or reduced my hobbies and daily activities because I\'m concerned about falling or bleed risk.'
        ]
      },
      empowerYourTreatmentJourney: {
        title: 'Empower your treatment journey',
        summary: 'Print and share your doctor discussion guide.',
        text: 'Take the next step. Use your guide to talk to your doctor to determine if the WATCHMAN Implant' +
          ' is right for you.'
      }
    }
  },
  preview_ddg_hero_mobile_only: {
    grey_header_text: (name) => name + ', explore your personalized discussion guide.',
    grey_subheader_text_first_visit: (email) => 'Use this guide to talk to your doctor about the WATCHMAN ' +
      'Implant at your next appointment. This guide is best viewed on a computer or tablet device. We emailed you a ' +
      'copy at ' + email + '.',
    grey_subheader_text_second_visit: (email) => 'Use this guide to talk to your doctor about the WATCHMAN Implant at your next ' +
      'appointment. This guide is best viewed on a computer or tablet device. We emailed you a copy at ' + email +
      ' when you took the survey.',
    resend_link_text: 'Re-send Discussion Guide',
    grey_subheader_text_after_resend: 'Use this guide to talk to your doctor about the WATCHMAN Implant at your next ' +
      'appointment. This guide is best viewed on a computer or tablet device.',
    grey_subheader_confirm_heavy: (email) => 'An email with a link to view your discussion guide on your computer ' +
      'or tablet has been sent to ' + email + '.',
    grey_subheader_confirm_emphasis: 'If you don\'t see it, look in your junk or spam folders.'
  },
  preview_ddg_hero: {
    grey_subheader_desktop_text: (email) => 'Use this guide to talk to your doctor about the WATCHMAN Implant at your next appointment. We emailed you a copy at ' + email + '.',
    grey_subheader_mobile_text: (email) => 'Use this guide to talk to your doctor about the WATCHMAN Implant at your next appointment. ' +
      'This guide is best viewed on a computer or tablet device. We emailed you a copy at  ' + email + '.',
    share_guide: 'Sharing your discussion guide is easy.',
    subheader_desktop: 'Email this guide directly to your doctor by searching for them in our system or print ' +
      'a copy to discuss with your doctor at your next appointment.',
    subheader_mobile: 'Email this guide directly to your doctor by searching for them in our system.',
    unqualified: {
      header: (name) => {
        return name + ', explore your personalized discussion guide.'
      },
      subheader: (email) => {
        return 'A copy of your guide has been emailed to you at ' + email + '. If you believe the information in your ' +
          'discussion guide may be incorrect or has changed, review your previous responses and make changes to get ' +
          'your updated results.'
      },
      subheader_orange: 'Your doctor understands your health situation best and it’s important to review this discussion' +
        ' guide with them to confirm your results or discuss all of your stroke risk reduction options.'
    }
  },
  preview_brochure_hero: {
    authenticated: {
      share_guide: 'Sharing information about the WATCHMAN Implant is easy.',
      subheader: 'Email this brochure directly to your family and friends and print a copy to discuss with ' +
        'your doctor at your next appointment.'
    },
    unauthenticated: {
      share_guide: 'Learning more about the WATCHMAN Implant is easy.',
      subheader: 'Print a copy to discuss with your doctor at your next appointment.'
    }
  },
  preview_guide_next_steps: {
    step_one: (email) => '1– Check your email at ' + email + ' to find a copy of your discussion guide.',
    step_two: '2– Use your personal discussion guide to make it easy to talk to your doctor about the WATCHMAN ' +
      'Implant at your next appointment. Print a copy to bring to your next appointment.',
    step_three: '3– Let your doctor know you are interested in exploring the WATCHMAN Implant. ' +
      'Send this guide directly to them by searching for their email in our system.'
  },
  guideOptions: {
    title: 'WHAT DO I DO WITH THIS GUIDE?',
    subTitle: 'Let your doctor know you are interested in exploring the WATCHMAN Implant.',
    steps: {
      print: {
        text: 'Print a copy to bring to your next appointment.',
        buttonCopy: {
          normal: 'Print your guide',
          override: {
            tablet: 'Print'
          }
        },
        mdi: 'mdi-printer',
        visibleInMobile: true,
        visibleInTablet: true
      },
      printQualified: {
        text: 'Print a copy to bring to your next appointment.',
        buttonCopy: {
          normal: 'Print',
          override: {
            tablet: 'Print your guide'
          }
        },
        mdi: 'mdi-printer',
        visibleInMobile: true,
        visibleInTablet: true
      },
      printQualifiedLongVariant: {
        buttonCopy: {
          normal: 'Print your guide',
          override: {
            tablet: 'Print your guide'
          }
        },
        mdi: 'mdi-printer',
        visibleInMobile: true,
        visibleInTablet: true
      },
      share: {
        text: 'Send this guide directly to your doctor by searching for their email in our system.',
        buttonCopy: {
          normal: 'Share',
          override: {
            tablet: 'Share results with doctor'
          }
        },
        mdi: 'mdi-plus',
        visibleInMobile: true,
        visibleInTablet: true
      },
      shareQualified: {
        text: 'Send this guide directly to your doctor by searching for their email in our system.',
        buttonCopy: {
          normal: 'Share',
          override: {
            tablet: 'Share results with doctor'
          }
        },
        mdi: 'mdi-share-variant',
        visibleInMobile: true,
        visibleInTablet: true
      },
      shareQualifiedLongVariant: {
        buttonCopy: {
          normal: 'Share results with your doctor',
          override: {
            tablet: 'Share results with your doctor'
          }
        },
        mdi: 'mdi-share-variant',
        visibleInMobile: true,
        visibleInTablet: true
      },
      download: {
        text: 'Download a copy of your guide.',
        buttonCopy: {
          normal: 'Download guide',
          override: {
            tablet: 'Download'
          }
        },
        target: '_blank',
        visibleInMobile: false,
        visibleInTablet: true
      },
      downloadQualified: {
        text: 'Download a copy of your guide.',
        buttonCopy: {
          normal: 'Download',
          override: {
            tablet: 'Download'
          }
        },
        mdi: 'mdi-download',
        target: '_blank',
        visibleInMobile: false,
        visibleInTablet: false
      },
      downloadQualifiedLongVariant: {
        buttonCopy: {
          normal: 'Download your guide',
          override: {
            tablet: 'Download your guide'
          }
        },
        mdi: 'mdi-download',
        target: '_blank',
        visibleInMobile: false,
        visibleInTablet: false
      }
    }
  },
  consent_only_page: {
    introHeaderCopy: 'Consent Record',
    confirmationHeader: 'Thank you',
    confirmationMessage: 'The consent record has been submitted.'
  },
  care_team_page: {
    introHeaderCopy: 'Patient Record',
    confirmationHeader: 'Thank you',
    confirmationMessage: 'The patient record has been submitted.'
  },
  have_questions_menu: {
    home: {
      title: 'What can we help you with?'
    },
    email_education_specialist: 'Email an Education Specialist',
    flyout_content: {
      is_watchman_covered: {
        title: 'Is the WATCHMAN Implant covered by insurance?',
        image: damImages.have_questions_menu.icon_insurance,
        copy: 'Medicare covers the WATCHMAN Implant for eligible patients and an increasing number of other insurers ' +
          'do, too. Insurance coverage can vary significantly from one plan to another. Be sure to contact your ' +
          'insurance carrier for any specific questions.',
        auth: true
      },
      how_watchman_works: {
        title: 'How does the WATCHMAN Implant work?',
        image: damImages.have_questions_menu.icon_left_atrial,
        copy: 'The WATCHMAN Implant permanently closes off the left atrial appendage so blood clots can\'t form ' +
          'there. This reduces stroke risk without blood thinners. More than 90% of stroke causing blood clots form ' +
          'in a small pocket of the heart called the LAA.',
        auth: true,
        link: {
          copy: 'Learn more',
          route: { name: localRouteNames.howDoesItWork }
        }
      },
      talk_to_someone: {
        title: 'Can I talk to someone who has the WATCHMAN Implant?',
        image: damImages.have_questions_menu.icon_ambassadors,
        copy: 'Yes, WATCHMAN Ambassadors are people who have received the WATCHMAN Implant and have volunteered to share ' +
          'their honest, candid experience with others considering the WATCHMAN Implant.',
        auth: false,
        link: {
          copy: 'Connect with a WATCHMAN Ambassador',
          route: { name: localRouteNames.ambassadorLibrary }
        }
      },
      need_help_preparing: {
        title: 'Need help preparing for your next appointment?',
        image: damImages.have_questions_menu.icon_plan,
        copy: 'Bring your personalized discussion guide to your next doctor\'s appointment, it will help start a ' +
          'conversation about the WATCHMAN Implant.',
        auth: true,
        link: {
          copy: 'Preview your personalized guide',
          route: { name: localRouteNames.previewGuide }
        }
      }
    }
  },
  is_it_safe: {
    hero: {
      title: 'Is it safe?',
      body: 'The WATCHMAN Implant is the first and most implanted LAAC device in the US with the longest ' +
        'proven safety record.',
      videoId: '783334053'
    },
    main_body_content: {
      body_title: 'Proven Safety Record',
      main_icon_block: {
        copy: 'Over 300,000 people have already received the WATCHMAN Implant. ' +
          'With almost 20 years of clinical and real-world experience — including ' +
          '10 clinical trials — it is proven, safe and effective.'
      },
      doctor_recommendations: [
        {
          copy: 'The WATCHMAN Implant is the #1 doctor recommended LAAC Implant.',
          icon: localAssets.iconNumberOneHashtag
        },
        {
          copy: 'The WATCHMAN Implant is safe with a very low major complication rate of 0.5%, ' +
            'which is similar to the rate of a cardiac ablation procedure.',
          icon: localAssets.iconHeartbeatNoCircle,
          subscript: '1',
          additional_info_bullet: '*'
        }
      ]
    },
    factoid: {
      copy: 'The latest generation WATCHMAN FLX Implant is an advancement that gives more people than ' +
        'ever a safe, effective alternative to blood thinners.',
      image: damImages.man_jumping_off_dock
    }
  },
  patient_stories: {
    hero: {
      header: 'Patient Stories',
      patient_quote: 'With WATCHMAN, I\'m free to do just about anything.',
      patient_desc: 'Bill chose WATCHMAN as a permanent solution to help reduce the risk of stroke without the ' +
        'risk of bleeding that can come with the long-term use of blood thinners.',
      quote_source: 'Bill, WATCHMAN Implant recipient',
      patient_image: {
        url: damImages.bill_headshot.url,
        desc: damImages.bill_headshot.desc
      }
    },
    testimonial: {
      heading: 'You\'re looking at a success story',
      sub_heading: 'Barry, WATCHMAN Implant recipient',
      expandable_paragraph: {
        button_read_more: 'Read more about Barry',
        button_read_less: 'Read less about Barry',
        primary_text: 'Barry\'s always been an active guy. He\'s in his 80s, and still skiing all over the world. He\'s a ' +
          'competitive tennis player who\'s ranked #9 in his age group in all of New England. But about 8 years ago, ' +
          'atrial fibrillation (AFib) threatened to derail him.',
        expanded_text: [
          'Barry had survived many stressful situations—he\'d worked for decades amidst the the hustle and bustle of New' +
          ' York City—but his AFib diagnosis was a different challenge. He worried constantly about the stroke risk.',

          'Barry\'s doctor started him on Coumadin®—a treatment that required monthly blood tests. Barry knew Coumadin® ' +
          'increased his bleeding risk, and worried about accidentally cutting himself, or falling while skiing and ' +
          'hitting his head.',

          'Then, his cardiologist talked to him about the WATCHMAN Implant. Tired of Coumadin® and all the hassle that ' +
          'came with it, Barry said, “Let\'s do it.” Post-procedure, Barry raved about how easy the recovery was. These ' +
          'days, he\'s spending much less time worrying about stroke risk, and much more time conquering the tennis court.' +
          ' And he couldn\'t be happier about it.'
        ]
      }
    }
  },
  ambassadorPreview: {
    title: 'Meet WATCHMAN Ambassadors',
    altTitle: 'More WATCHMAN Ambassadors',
    copy: 'WATCHMAN Ambassadors are people who have received the WATCHMAN Implant and have volunteered to share their ' +
      'honest, candid experience with others considering the WATCHMAN Implant.',
    buttonText: 'Meet more Ambassadors'
  },
  why_choose_watchman: {
    hero: {
      header: 'Why choose the WATCHMAN Implant?',
      copy: 'The WATCHMAN Implant may be a life changing alternative to the lifelong use of blood thinners ' +
        'through a one-time minimally invasive procedure that reduces stroke risk and bleeding worry for life.',
      reference: '1'
    },
    factoid: {
      copy: 'The WATCHMAN Implant is a one-time procedure and one-time cost. This means you can save money over time.',
      image: damImages.man_climbing_treehouse
    }
  },
  how_does_it_work: {
    hero: {
      title: 'How does it work?',
      body: 'The WATCHMAN Implant is an alternative to blood thinners that reduces the risk of stroke.',
      videoId: '225459013'
    },
    know_the_connection: {
      title: 'Know the connection between AFib and stroke',
      main: {
        copy: 'Atrial fibrillation (AFib), affects your heart\'s ability to pump blood normally. This can cause blood ' +
          'to pool in the heart and form clots. When clots escape and travel to the brain, they can cause a stroke.',
        reference: '1, 2'
      },
      points: {
        style: 'image',
        items: {
          item1: {
            copy: 'More than 90% of stroke-causing blood clots are formed in a part of the heart called the left ' +
              'atrial appendage, or LAA.',
            reference: '1',
            image: localAssets.graphicHeartLaa
          },
          item2: {
            copy: 'The WATCHMAN Implant fits into your LAA to close it off, preventing clots from escaping to the ' +
              'brain. Closing off this part of the heart effectively reduces stroke risk.',
            image: localAssets.graphicLaaWithImplant
          }
        }
      },
      factoid: {
        copy: 'The WATCHMAN Implant does not come with the bleeding risks associated with the long-term use of ' +
          'blood thinners.',
        reference: '3',
        image: damImages.speedy_grandson
      }
    }
  },
  authentication_modal: {
    email_lookup_block: {
      title_strong: 'Already taken our survey to see if the WATCHMAN Implant is right for you?',
      title_regular: 'Enter your email to unlock your results, personalized doctor discussion guide, ' +
        'and exclusive resources to explore life without blood thinners.',
      input_placeholder: 'Email Address',
      email_not_found_error: 'We can’t find your results with this email address. ' +
        'Please try a different email address or click on the link below to retake the survey.',
      footer: 'If you haven’t taken our survey, answer a few questions about your AFib to see ' +
        'if the WATCHMAN Implant may be right for you.'
    },
    authentication_modal_complete: {
      header: 'We sent you an email at ',
      copy1: 'You will receive an email shortly with a direct link to view your personalized doctor discussion' +
        ' guide & results. If you don\'t see it, check your junk or spam folder.',
      copy2: {
        strong: 'Still have questions?',
        body: ' Contact a WATCHMAN Education Specialist.'
      }
    }
  },
  ambassadors_authentication_modal: {
    title_strong: 'Connect with a WATCHMAN Ambassador',
    title_regular: {
      part1: 'If you have already taken our survey, enter your email or call',
      part2: 'to talk to a WATCHMAN Education Specialist who can help connect you with an Ambassador.'
    },
    input_placeholder: 'Email Address',
    email_not_found_error: 'We can’t find your results with this email address. ' +
      'Please try a different email address or click on the link below to retake the survey.',
    footer: 'If you haven’t taken our survey, it only takes a few minutes to find out if the WATCHMAN Implant ' +
      'might be right for you.'
  },
  ambassadors_modal_complete: {
    header: 'We sent you an email at',
    copy1: 'You will find a link in that email to return to your personalized results and will allow you to connect with a WATCHMAN Ambassador.' +
      '  If you don’t see it, look in your junk or spam folders.',
    copy2: 'Questions?',
    copy3: 'Call',
    copy4: 'to talk to a WATCHMAN Education Specialist.'
  },
  email_brochure: {
    view_this_brochure: 'View this brochure to learn even more about the WATCHMAN Implant.',
    get_a_copy: 'Get a copy of the WATCHMAN Implant brochure delivered right to your inbox.',
    has_been_sent: 'An email with a link to download this brochure has been sent to you.',
    check_junk_folder: 'If you don\'t see it, look in your junk or spam folders.'
  },
  resubmission_survey: {
    questions_header_text: {
      review_and_update: 'Please review and update if needed.',
      highlighted_selection: 'Your previous selection is highlighted in green.',
      next_question_to_continue: 'Click "Next question" to continue.',
      submit_to_continue: 'Click "Submit" to continue.'
    }
  },
  legalDisclaimer: 'There are risks associated with all medical procedures. Please talk with your doctor about the risks and benefits associated with the WATCHMAN Implant.',
  ambassador_banner: {
    learn_more: {
      header_text: 'Connect with a WATCHMAN Ambassador',
      body_text: 'Ambassadors have received the WATCHMAN Implant and have volunteered to share their honest, candid experience.',
      link_text: 'Learn more',
      one_on_one_text: 'One-on-one conversations with WATCHMAN Implant recipients about their experience.'
    },
    see_how: {
      copy: 'Get exclusive access to connect with a WATCHMAN Ambassador.',
      link_text: 'See how'
    }
  },
  appointment_preparation_page: {
    nav_bar_title: 'Use your personal discussion guide to talk with your doctor.',
    hero: {
      header: 'Get back to living your life your way. Free yourself from blood thinners with the WATCHMAN Implant.',
      subHeader: 'Use your personal discussion guide to make it easy to ask your doctor about the WATCHMAN Implant at your next appointment.'
    },
    video_section: {
      header: 'How to prepare for your appointment:',
      bullets: [
        { copy: 'Bring a copy of your guide to your next appointment to help start the conversation' },
        { copy: 'Be ready to talk about your challenges with blood thinners' },
        { copy: 'Write down any questions you have for your doctor' }
      ]
    }
  },
  info_landing_page: {
    factoid: {
      fact: '83% of people with AFib taking a blood thinner say they would be willing to try a different treatment ' +
        'to help reduce their risk of stroke.',
      image: damImages.photo_linda_and_husband
    },
    auth_modal_link: `Already answered these questions? <span>Sign in now</span>`
  },
  resources_video_library: {
    intro_header_text: 'Why choose the WATCHMAN Implant',
    video_description_text: 'Hear Dr. Kanj, MD, Cardiologist talk about the advantages of the WATCHMAN Implant over blood thinners.',
    video_length_text: 'Video Length: 2:10'
  },
  talk_to_doctor_page: {
    intro_header_text: 'Prepare to talk to your doctor',
    intro_copy_text: 'If you’re not satisfied with your current treatment for managing AFib related stroke risk, you ' +
      'can talk to your doctor about the WATCHMAN Implant. Here’s what to expect.'
  },
  routeNames: {
    questionnaire: localRouteNames.questionnaire,
    results: localRouteNames.results,
    emailPreview: localRouteNames.emailPreview,
    emailProvider: localRouteNames.emailProvider,
    emailFriends: localRouteNames.emailFriends,
    emailFriendsBrochure: localRouteNames.emailFriendsBrochure,
    emailEducationSpecialistInternal: localRouteNames.emailEducationSpecialistInternal,
    emailEducationSpecialistExternal: localRouteNames.emailEducationSpecialistExternal,
    emailEducationSpecialistConfirmationInternal: localRouteNames.emailEducationSpecialistConfirmationInternal,
    emailEducationSpecialistConfirmationExternal: localRouteNames.emailEducationSpecialistConfirmationExternal,
    referral: localRouteNames.referral,
    registerWatchman: localRouteNames.registerWatchman,
    patientStories: localRouteNames.patientStories,
    howDoesItWork: localRouteNames.howDoesItWork,
    isItSafe: localRouteNames.isItSafe,
    whyChooseWatchman: localRouteNames.whyChooseWatchman,
    previewGuide: localRouteNames.previewGuide,
    previewBrochure: localRouteNames.previewBrochure,
    ambassadorProfile: localRouteNames.ambassadorProfile,
    ambassadorLibrary: localRouteNames.ambassadorLibrary,
    ambassadorMeetingChoice: localRouteNames.ambassadorMeetingChoice,
    ambassadorSendAnEmail: localRouteNames.ambassadorSendAnEmail,
    ambassadorRequestAPhoneCall: localRouteNames.ambassadorRequestAPhoneCall,
    appointmentPreparation: localRouteNames.appointmentPreparation,
    resources: localRouteNames.resources,
    videoLibrary: localRouteNames.videoLibrary,
    becomeAnAmbassador: localRouteNames.becomeAnAmbassador,
    whatToExpect: localRouteNames.whatToExpect,
    talkToDoctor: localRouteNames.talkToDoctor,
    viewYourRisks: localRouteNames.viewYourRisks,
    understandingCost: localRouteNames.understandingCost,
    questionnaireResubmission: localRouteNames.questionnaireResubmission
  },
  videoLibraryPage: {
    header: 'Learn more about the WATCHMAN Implant with Dr. Kanj, MD, Cardiologist',
    subheader: '“My patients with WATCHMAN no longer need to worry about taking a daily medication, or the ' +
      'bleeding risks associated with blood thinners.”',
    signature: 'Dr. Kanj, MD, Cardiologist'
  },
  libraryVideos: {
    viewGettingWMDevice: 'the full video: The WATCHMAN Implant: An Introduction.',
    doctor_kanj_compensation: 'Dr. Kanj was compensated for his time in the creation of these videos. ',
    aFibAndStrokeRisk: {
      title: 'AFib and Stroke Risk',
      videos: [
        {
          videoId: '783333021',
          title: 'What is atrial fibrillation (AFib)?',
          duration: '4:19',
          thumbnail: damImages.vimeo_what_is_afib
        },
        {
          videoId: '783325230',
          title: 'How is AFib related to stroke risk?',
          duration: '3:01',
          thumbnail: damImages.vimeo_afib_stroke_risk
        },
        {
          videoId: '783324853',
          title: 'How do I manage AFib & stroke risk?',
          duration: '1:58',
          thumbnail: damImages.vimeo_manage_afib_stroke_risk
        }
      ]
    },
    aboutTheWatchmanImplant: {
      title: 'About the WATCHMAN Implant',
      videos: [
        {
          videoId: '783325537',
          title: 'How does the WATCHMAN Implant work?',
          duration: '2:12',
          thumbnail: damImages.vimeo_how_does_the_watchman_implant_work
        },
        {
          videoId: '783332172',
          title: 'How is the WATCHMAN Implant different from blood thinners?',
          duration: '3:49',
          thumbnail: damImages.vimeo_different_from_blood_thinners
        },
        {
          videoId: '783334053',
          title: 'Why choose the WATCHMAN Implant?',
          duration: '2:10',
          thumbnail: damImages.vimeo_why_choose
        }
      ]
    },
    gettingTheWatchmanImplant: {
      title: 'Getting the WATCHMAN Implant',
      videos: [
        {
          videoId: '783331678',
          title: 'How do I talk to my doctor about the WATCHMAN Implant?',
          duration: '2:06',
          thumbnail: damImages.vimeo_talking_with_doctor
        },
        {
          videoId: '783325948',
          title: 'What can I expect during the procedure and recovery?',
          duration: '3:54',
          thumbnail: damImages.vimeo_procedure
        }
      ]
    }
  },
  talkToDoctor: {
    tipsFromCardiologist: {
      title: 'Tips from a cardiologist',
      subTitle: 'Dr. Kanj, MD, Cardiologist, shares his advice for a conversation with your doctor:',
      steps: [
        {
          copy: 'Talk about whether you\'re at risk for a stroke'
        },
        {
          copy: 'Understand your risk of bleeding if you\'re taking blood thinners'
        },
        {
          copy: 'Ask your doctor to factor your lifestyle and preferences, to help make the best decision for you'
        }
      ]
    },
    nextSteps: {
      steps: [
        {
          title: 'Get Support',
          summary: 'Our team of WATCHMAN Education Specialists are trained professionals with healthcare experience. ' +
            'We can help you prepare for your conversation with your doctor. And we can keep in touch after your appointment to help answer any questions you have.'
        },
        {
          title: 'Understand the procedure',
          summary: 'Your WATCHMAN implanting physician will tell you your next steps, which may include a physical exam and imaging.'
        }
      ]
    }
  },
  whatToExpect: {
    hero: {
      header: 'What to expect with the WATCHMAN Implant procedure',
      subheader: 'The decision to get the WATCHMAN Implant is shared between you and your doctor. Together, you\'ll decide whether the WATCHMAN Implant is the right choice for you. If so, here is what comes next.'
    },
    sections: {
      preparingForYourProcedure: {
        title: 'Preparing for your procedure',
        summary: 'See how to prepare to get the WATCHMAN Implant.',
        content: {
          description: 'Your doctor and care team will help you prepare. Make sure you understand all the instructions, ' +
            'including what medications you should and should not take, and ask any questions you have.',
          checks: [
            {
              headline: 'Get approval from insurance',
              copy: 'The WATCHMAN Implant is covered for eligible Medicare patients and does not require advance ' +
                'approval. If you have commercial insurance, you may need prior authorization from your plan. Your ' +
                'doctor\'s office may be able to assist.'
            },
            {
              headline: 'Choose a medical center',
              copy: 'You and your doctor will decide on a center and they will provide a referral. Typically, the ' +
                'medical center will call you to schedule a consultation, or your doctor\'s office may schedule it for you.',
              showMedCenters: true
            },
            {
              headline: 'Set the date for your WATCHMAN Implant procedure',
              copy: 'Your doctor and care team can help you set a procedure date.'
            }
          ]
        }
      },
      dayOfProcedure: {
        title: 'Day of procedure',
        summary: 'Learn what to expect on the day of your WATCHMAN Implant procedure.',
        content: {
          description: 'Before the WATCHMAN Implant procedure, you may have an imaging test which takes pictures of your ' +
            'heart so the doctor can see your left atrial appendage (LAA) and to make sure you don\'t have an existing blood clot. ' +
            'Here\'s what comes next:',
          steps: [
            {
              summary: 'To begin the procedure, your doctor makes a small cut in your upper leg and inserts a narrow tube.',
              image: localAssets.graphicDayOfProcedureStep1
            },
            {
              summary: 'Your doctor guides the WATCHMAN Implant through the tube, into your left atrial appendage (LAA).',
              image: localAssets.graphicDayOfProcedureStep2
            },
            {
              summary: 'The procedure is typically done under general anesthesia. People who get the WATCHMAN Implant ' +
                'generally stay in the hospital for a day or less.',
              image: localAssets.graphicDayOfProcedureStep3
            },
            {
              summary: 'After the procedure, your own heart tissue will grow over the WATCHMAN Implant to form a ' +
                'barrier against blood clots.',
              image: localAssets.graphicDayOfProcedureStep4
            }
          ]
        },
        footer: {
          icon: localAssets.iconQuotesWhite,
          image: damImages.what_to_expect.day_of_procedure_doris,
          title: 'The procedure was a piece of cake. Getting my tonsils out was worse than having this done. ' +
            'It was the easiest thing I\'ve ever done. It couldn\'t have been simpler.',
          summary: 'Doris, WATCHMAN Implant recipient & WATCHMAN Ambassador'
        }
      },
      afterProcedure: {
        title: 'After procedure &amp; recovery',
        summary: 'See what the first year with the WATCHMAN Implant typically looks like.',
        arrowBlocks: {
          optionA: {
            title: 'Option A: Antiplatelet',
            steps: [
              {
                line1: 'Antiplatelet + Aspirin',
                line2: '6 months'
              },
              {
                line1: 'Aspirin',
                line2: 'Ongoing'
              }
            ],
            content: 'Your doctor will prescribe anti-platelet medicine called clopidogrel (also known as Plavix®) ' +
              'and aspirin for 6 months. At your 6-month appointment, your doctor may stop the anti-platelet ' +
              'medicine and you\'ll continue to take aspirin on an ongoing basis.'
          },
          optionB: {
            title: 'Option B: Short-term blood thinners',
            steps: [
              {
                line1: 'Blood Thinner',
                line2: '45 days'
              },
              {
                line1: 'Antiplatelet + Aspirin',
                line2: '6 months'
              },
              {
                line1: 'Aspirin',
                line2: 'Ongoing'
              }
            ],
            content: 'You may be prescribed a short-term blood thinner (anticoagulant), typically for 45 days. Then ' +
              'your doctor may stop blood thinners and put you on an anti-platelet and aspirin until 6 months ' +
              'post-procedure. After that, you\'ll continue to take aspirin on an ongoing basis.'
          }
        }
      }
    }
  },
  understandingCostCoverage: {
    bannerText: {
      header: 'Concerned with the cost of blood thinners?',
      header_heavy: 'Concerned with the cost of blood thinners?',
      copy: 'The WATCHMAN Implant is an affordable option. As a one-time alternative,' +
        ' the WATCHMAN Implant can save you money over time.',
      reference: ''
    },
    understandingCost: {
      title: 'Understanding Cost',
      main: {
        copy: 'Is the cost of your blood thinner a concern for you?' +
          ' Do you ever skip or split your recommended dose to extend your prescription?' +
          ' Talk to your doctor about these challenges to see if the WATCHMAN Implant may be right for you. '
      },
      points: {
        style: 'icon',
        items: {
          item1: {
            copy: 'The WATCHMAN Implant can be less expensive than being on a blood thinner.' +
              ' In fact, the total out-of-pocket spending for the WATCHMAN Implant may be lower' +
              ' than warfarin by year 2 and half the cost by year 5.',
            reference: '1',
            image: localAssets.iconBloodThinners
          },
          item2: {
            copy: 'Unlike blood thinners, which must be taken every day for life and have ongoing cost,' +
              ' the WATCHMAN Implant is a one-time procedure and one-time cost.' +
              ' This means you can save money over time.',
            image: localAssets.iconPills
          }
        }
      },
      factoid: {
        copy: 'The estimated out-of-pocket cost for many people covered by Medicare is no more than $2,600.',
        reference: '2',
        image: damImages.woman_talking_to_doctor
      }
    },
    insuranceApproval: {
      title: 'Getting Insurance Approval',
      summary: 'Before your WATCHMAN Implant procedure, it is important to get insurance approval. The WATCHMAN ' +
        'Implant is covered nationally for many people by Medicare and an ever-increasing number of commercial ' +
        'insurers. Coverage may differ depending on whether you have Medicare or commercial insurance.',
      steps: [
        {
          copy: 'You may need prior authorization or pre-certification from your plan provider.'
        },
        {
          copy: 'Your doctor\'s office will likely work with you to complete this process. If your coverage is ' +
            'denied, you can work with your physician on an appeal.'
        },
        {
          copy: 'If you have questions or concerns about your coverage, please reach out directly to your insurance ' +
            'carrier. They should be able to help answer any questions about your plan-specific coverage.'
        }
      ],
      footer: 'Your cardiologist\'s office may be able to help you find out what your coverage is. Insurance ' +
        'coverage can vary significantly from one plan to another. Be sure to contact your insurance carrier for any ' +
        'specific questions.'
    }
  },
  surveyQuestions: {
    tooltip: {
      html: '<p>Atrial fibrillation (AFib), affects your ' +
        'heart' + '\'s' + ' ability to pump blood normally. This can cause blood to pool in the heart and form clots. ' +
        'When clots escape and travel to the brain, they can cause a stroke.<sup>1, 2</sup></p>'
    }
  },
  knowYourRisks: {
    heading: 'Know your risks with AFib',
    subheading: 'Understanding your stroke risk and bleed risk can help you make a plan with your doctor to keep ' +
      'you protected.',
    checksList: [
      {
        copy: 'Blood thinners are one treatment option to reduce your stroke risk. But because blood thinners help ' +
          'prevent clots by thinning the blood, they increase the risk of bleeding.',
        subscript: '1'
      },
      {
        copy: 'People who take blood thinners for 10 years may be at a 6–9x higher risk of bleeding due to ' +
          'prolonged blood thinner use.',
        subscript: '*2'
      },
      {
        copy: 'The WATCHMAN Implant is a proven, safe alternative to blood thinners that permanently reduces stroke ' +
          'risk and bleeding worry.'
      }
    ],
    understandingYourStrokeRisk: {
      heading: 'Understanding your stroke risk',
      mainText: 'Your stroke risk is defined by your CHA₂DS₂-VASc score. Based on your survey results, you may have',
      mainTextTwo: 'conditions that factor into your score. This score, along with other individual factors, ' +
        'is commonly used by doctors to evaluate stroke risk in people with AFib to help guide treatment options.',
      didYouKnowTextHeavy: 'Did you know?',
      didYouKnowTextLight: 'When living with AFib, you are 5x more likely to have a stroke, and the risk ' +
        'of a stroke significantly increases as you get older.',
      subscript: '1',
      informationText: 'To update any of this information, please contact our WATCHMAN Education Specialists at',
      learnMoreText: 'Learn about your score',
      whatDoesThisMeanText: 'What does this mean?'
    },
    understandingYourBleedRisk: {
      hasbledScorePresent: {
        heading: {
          copy: 'Understanding your bleed risk',
          class: 'center'
        },
        mainText: 'Your bleed risk is defined by your HAS-BLED score. This score, along with other individual factors, is commonly used by doctors to evaluate bleed risk in people with AFib on blood thinners to help guide treatment options.',
        question: 'What do these numbers mean?',
        answer: '0 or 1 = Low Range, 2 = Moderate Range, 3+ = High Range',
        tilesHeaderText: 'Based on your survey results, you may have x conditions that factor into your score:',
        learnHow: 'Learn how HAS-BLED is calculated'
      },
      hasbledScoreMissing: {
        heading: {
          copy: 'Get your personalized bleed risk score'
        },
        mainText: 'Your bleed risk is defined by your HAS-BLED score. This score, along with other individual factors, is commonly used by doctors to evaluate bleed risk in people with AFib on blood thinners to help guide treatment options.',
        subText: 'It looks like the bleed risk score is missing from your eligibility survey responses. Let’s fix that.',
        getYourBleedRisk: 'Get your personalized bleed risk score today.',
        buttonCopy: 'Update your survey responses'
      },
      didYouKnow: {
        didYouKnowTitle: 'Did you know?',
        didYouKnowCopy: 'On blood thinners, a fall or injury while enjoying your favorite hobbies and activities could escalate to a major bleeding event.'
      }
    },
    withoutBloodThinnersContent: {
      title: 'Is life without blood thinners possible? Yes!',
      summaryText: 'The WATCHMAN Implant is an alternative to blood thinners that reduces your risk of stroke, ' +
        'without the bleeding worry that comes with lifelong blood thinners.',
      questionSectionText: 'Do blood thinners keep you from living life your way?',
      questionSectionBulletPoints: [
        { text: 'Have you noticed bruising or bleeding from everyday activities?' },
        { text: 'Have you had to stop hobbies or daily activities due to fear of falls or bleeds?' },
        { text: 'Do you sometimes forget to take your blood thinner?' },
        { text: 'Have you had challenges with certain foods & blood thinner interactions?' },
        { text: 'Have you tried more than one blood thinner? ' },
        { text: 'Do struggle with the ongoing cost of blood thinner medications?' }
      ],
      questionSectionSubText: 'If you answered yes to any of these questions, talk to your doctor to see if the ' +
        'WATCHMAN Implant may be right for you.',
      heartbeatSection:
        {
          copy: 'The WATCHMAN Implant has a proven safety record with a high implant success rate of 99%, ' +
            'and a low major complication rate of 0.5%.<sup>*3</sup>'
        }
    },
    tooltip: {
      chadVasc: 'Your stroke risk is defined by your CHA₂DS₂-VASc score. This score, along with other individual factors, ' +
        'is commonly used by doctors to evaluate stroke risk in people with AFib to help guide treatment options.',
      hasBleed: 'Your bleed risk is defined by your HAS-BLED score. This score, along with other individual factors, is commonly used by ' +
        'doctors to evaluate bleed risk in people with AFib on blood thinners to help guide treatment options.'
    },
    knowYourRiskText: 'Your stroke risk, bleed risk, and lifestyle factor into if the WATCHMAN Implant may be right for you. ' +
      'Talk to your doctor about your scores and if the WATCHMAN Implant might be right for you.',
    IconCopyListKnowYourRiskText: {
      data: [{
        copy: 'Your stroke risk, bleed risk, and lifestyle factor into if the WATCHMAN Implant may be right for you. ' +
          'Talk to your doctor about your scores and if the WATCHMAN Implant might be right for you.'
      }]
    },
    nextSteps: {
      steps: [
        {
          title: 'Connect with a WATCHMAN Education Specialist',
          summary: 'WATCHMAN Education Specialists are trained professionals with ' +
            'healthcare experience available to answer your questions as you learn more ' +
            'about stroke risks, bleed risks, and alternatives to blood thinners.'
        },
        {
          title: 'Talk to your doctor',
          summary: 'Bring a copy of your personal results to your next appointment and be ready to talk with your doctor about your risks and ' +
            'challenges with blood thinners.'
        }
      ]
    },
    strokeRiskModal: {
      title: 'Your stroke risk',
      firstSection: {
        firstContent: '<p>Your stroke risk is defined by your CHA₂DS₂-VASc score. This score, along with other ' +
          'individual factors, is commonly used by doctors to evaluate stroke risk in people with AFib to help guide treatment options.</p>',
        secondContent: 'What do these numbers mean?',
        thirdContent: '0 or 1 = Low Range, 2 = Moderate Range, 3+ = High Range',
        fourthContentPart1: 'Based on your survey results, you have',
        fourthContentPart2: 'conditions that factor into your score:'
      },
      secondSection: {
        title: '<p>These conditions factor into the CHA₂DS₂-VASc score, 9 is the maximum score:</p>',
        equations: [
          '<span class="content-text">Hypertension</span> = 1 point',
          '<span class="content-text">Congestive Heart Failure</span> = 1 point',
          '<span class="content-text">Age:</span> 65-74 = 1 point <span class="content-text">-OR-</span> 75 years or older = 2 points',
          '<span class="content-text">Diabetes</span> = 1 point',
          '<span class="content-text">Stroke History including mini or minor strokes</span> = 2 points',
          '<span class="content-text">Vascular disease</span> = 1 point',
          '<span class="content-text">Female</span> = 1 point'
        ],
        bottomInfo: 'To update this information, please contact our WATCHMAN Education Specialists at'
      }
    },
    modal: {
      bullets: [
        'Hypertension',
        'Abnormal kidney function',
        'Abnormal liver function',
        'Stroke History including mini or minor strokes',
        'History of major or minor bleeding',
        'Labile INR, a test showing that the blood is not clotting in a predictable manner',
        '66 years or older',
        'Need to take NSAIDs or need long-term dual antiplatelet therapy',
        'Consuming 8+ alcoholic drinks per week'
      ]
    }
  },
  patientStages: {
    qualified: 'Qualified',
    preQualified: 'Pre Qualified',
    educated: 'Educated',
    cardConsult: 'Cardiology Consultation',
    implantConsult: 'Implanter Consultation',
    implanted: 'Implanted',
    closed: 'Closed',
    new: 'New'
  },
  searchWithinMiles: [
    { value: 0, text: '&ndash; Select a distance &ndash;' },
    { value: 10, text: '10 Miles' },
    { value: 25, text: '25 Miles' },
    { value: 50, text: '50 Miles' },
    { value: 75, text: '75 Miles' },
    { value: 100, text: '100 Miles' }
  ],
  mosaicPictures: {
    qualifiedStage: {
      text: 'One Time. For a Lifetime. Over 400,000 people have left blood thinners behind with the WATCHMAN Implant.'
    },
    otherStages: {
      text: 'AFib doesn\'t have to mean a lifetime of blood thinners. The WATCHMAN Implant is a safe, minimally invasive, ' +
        'one-time implant that reduces your stroke risk without lifelong blood thinners.*'
    }
  },
  becomeAnAmbassador: {
    heading: 'Tell your story with the WATCHMAN Implant',
    subheading: 'Real experience matters. Share your story to inspire hope and show people what life is really like with the WATCHMAN Implant.',
    storyMattersContent: {
      heading: 'Your story matters.',
      subheading: 'WATCHMAN Ambassadors help people considering the WATCHMAN Implant feel informed, supported and confident by sharing their ' +
        'experience. WATCHMAN Ambassadors can tell their story in a variety of ways, such as through videos, written testimonials online, ' +
        'one-on-one conversations, and more.<br/><br/>This is an application to be a WATCHMAN Ambassador. Our team will contact you if an opportunity ' +
        'becomes available. WATCHMAN Ambassadors are volunteers and not compensated.',
      shareMyStoryText: 'Share my story'
    },
    videoContent: {
      headerText: 'Honest answers from real people.',
      videoDescriptionText: 'Meet Linda, one of our WATCHMAN Ambassadors.'
    },

    question1: [
      {
        label: 'Participate in a video',
        value: '0',
        tooltip: 'Videos let people hear your story in your own words. A video could be recorded at your house, doctor\'s office, ' +
          'or in a studio. We’d work with you to make sure you’re prepared and feel comfortable.'
      },
      {
        label: 'Do a written testimonial online',
        value: '1',
        tooltip: 'Written testimonials give people the chance to read and revisit your story in your own words. We\'d partner ' +
          'with you to write your story and post it online, such as on watchman.com.'
      },
      {
        label: 'Talk to people one-on-one over email',
        value: '2',
        tooltip: 'Talking to a real person can be a source of comfort. Our community manager would work with you to make sure you ' +
          'feel comfortable and prepared to have private, one-on-one conversations over email with people considering the WATCHMAN Implant. ' +
          'We\'ll also partner with you to create a short biography for our website and on social media, so patients can get to know you ' +
          'before they request to connect with you.'
      },
      {
        label: 'Talk to people one-on-one on the phone',
        value: '3',
        tooltip: 'Talking to a real person can be a source of comfort. Our community manager would work with you to make sure you feel ' +
          'comfortable and prepared to have private, one-on-one conversations live on the phone with people considering the WATCHMAN Implant. ' +
          'We\'ll also partner with you to create a short biography for our website and on social media, so patients can get to know you before ' +
          'they request to connect with you.'
      },
      {
        label: 'Speak to news outlets or reporters',
        value: '4',
        tooltip: 'When the WATCHMAN Implant is in the news, it can be helpful to have a real person share their experience. Typically, ' +
          'interviews will be done by email or phone, but sometimes there are opportunities to be featured on TV or radio. Our Boston Scientific ' +
          'media team would partner with you to prepare for the interview.'
      },
      {
        label: 'Attend and speak at live virtual or local education events',
        value: '5',
        tooltip: 'Boston Scientific partners with doctors to educate people with AFib about their stroke risk options in live events. ' +
          'It\'s helpful to hear from a real person with the WATCHMAN Implant and ask them questions. We\'d work with you to make sure ' +
          'you\'re prepared and feel comfortable. '
      }
    ],
    confirmationSteps: {
      stepsArray: [
        {
          header: 'Your application has been received',
          copy: 'A member of our team will be in touch if there are opportunities available that you might be interested in.'
        },
        {
          header: 'Consider other opportunities to tell your story',
          copy: 'In the meantime, keep living life on your terms with the WATCHMAN Implant. You can share your story by talking to friends & family, posting online and more.'
        },
        {
          header: 'Join the Facebook community',
          copy: `Join the <a href="${externalLinks.bsci_facebook}" target="_blank">private Facebook community</a> group to share your experience with people who are exploring the WATCHMAN Implant.`
        },
        {
          header: 'Find post procedural device support',
          copy: `Find resources for life with the WATCHMAN Implant, including frequently asked questions, on the <a href="${externalLinks.watchman_device_support}" target="_blank">Boston Scientific website.</a>`
        }
      ],
      headerData: {
        headerText: 'Your application is submitted!',
        headerSubText: ', thank you for your interest in becoming a WATCHMAN Ambassador. We\'ve received your application and you\'ll receive a confirmation email shortly.'
      }
    }
  },
  insuranceCarrierChoices: ['Aetna', 'Anthem', 'Blue Cross / Blue Shield', 'Cigna', 'United Healthcare', 'Other'],
  primaryInsuranceChoices: ['Commercial Insurance Plan', 'Health Insurance Exchange / ACA', 'Traditional Medicare',
    'Medicare Advantage Plan', 'Medicaid', 'Other'],
  raceAndEthnicityChoices: ['Asian', 'Black, African, or African American', 'Hispanic, Latino/a/x, or Spanish',
    'Middle Eastern or North African', 'Native American or Alaska Native', 'Native Hawaiian, Pacific Islander, or Oceanian',
    'White (not Hispanic or Latino)', 'White (Hispanic or Latino)', 'Multiracial', 'Prefer not to answer', 'Other']
}
