const HUB_SESSION_TYPE_KEY = 'spur-hub-session-type'
const HUB_SESSION_ID_KEY = 'spur-hub-session-id'
const BROCHURE_SENT_TO_EMAIL_KEY = 'spur-brochure-sent-to-email'
const MINI_LQ_SURVEY_ANSWERS_KEY = 'spur-mini-listening-question-survey-answers'
const PATIENT_RESULTS_KEY = 'spur-patient-results'
const AUTHENTICATED_STATUS = 'authenticated'
const UNAUTHENTICATED_STATUS = 'unauthenticated'
const QUALIFIED_STATUS = 'qualified'
const UNQUALIFIED_STATUS = 'unqualified'
const AMBASSADOR_LIST = 'ambassador-list'
const AGE_FILTER_LIST = 'age-filter-list'
const GENDER_FILTER_LIST = 'gender-filter-list'
const DDG_RESENT_TO_EMAIL_KEY = 'ddg-re-sent-to-email'
const IS_FIRST_TIME = 'is-first-time'
const DIGITALLY_EDUCATED_KEY = 'spur-digitally-educated'
const SMS_OR_NO_PHONE_MODAL_SHOWN_KEY = 'spur-sms-or-no-phone-modal-shown'

function isHubRoute (route) {
  return route.meta.isHubPage
}

function getRouteAuthenticationStatus () {
  return getHubSessionId() ? AUTHENTICATED_STATUS : UNAUTHENTICATED_STATUS
}

/* Session storage Setters/Getters */
function getHubSessionAuthenticationStatus () {
  return sessionStorage.getItem(HUB_SESSION_TYPE_KEY)
}

function setHubSessionAuthenticationStatus (newStatus) {
  sessionStorage.setItem(HUB_SESSION_TYPE_KEY, newStatus)
}

function getHubSessionId () {
  return sessionStorage.getItem(HUB_SESSION_ID_KEY)
}

function setHubSessionId (sessionId) {
  sessionStorage.setItem(HUB_SESSION_ID_KEY, sessionId)
}

function getHubSessionEligibilityStatus () {
  if (getPatientResults() !== undefined && getPatientResults() !== null) {
    return getPatientResults().eligible ? QUALIFIED_STATUS : UNQUALIFIED_STATUS
  }
  return null
}

function getBrochureSentToEmailStatus () {
  return sessionStorage.getItem(BROCHURE_SENT_TO_EMAIL_KEY)
}

function setBrochureSentToEmailStatus (newStatus) {
  sessionStorage.setItem(BROCHURE_SENT_TO_EMAIL_KEY, newStatus)
}

function getMiniLqAnswers () {
  return sessionStorage.getItem(MINI_LQ_SURVEY_ANSWERS_KEY)
}

function setMiniLqAnswers (answers) {
  sessionStorage.setItem(MINI_LQ_SURVEY_ANSWERS_KEY, answers)
}

function getPatientResults () {
  return JSON.parse(sessionStorage.getItem(PATIENT_RESULTS_KEY))
}

function setPatientResults (resultsObject) {
  sessionStorage.setItem(PATIENT_RESULTS_KEY, JSON.stringify(resultsObject))
}

function getAmbassadorList () {
  return JSON.parse(sessionStorage.getItem(AMBASSADOR_LIST))
}

function setAmbassadorList (listObject) {
  sessionStorage.setItem(AMBASSADOR_LIST, JSON.stringify(listObject))
}

function getAgeFilterList () {
  return JSON.parse(sessionStorage.getItem(AGE_FILTER_LIST))
}

function setAgeFilterList (listObject) {
  sessionStorage.setItem(AGE_FILTER_LIST, JSON.stringify(listObject))
}

function getGenderFilterList () {
  return JSON.parse(sessionStorage.getItem(GENDER_FILTER_LIST))
}

function setGenderFilterList (listObject) {
  sessionStorage.setItem(GENDER_FILTER_LIST, JSON.stringify(listObject))
}

function getDDGReSentToEmailStatus () {
  return sessionStorage.getItem(DDG_RESENT_TO_EMAIL_KEY)
}

function setDDGReSentToEmailStatus (newStatus) {
  sessionStorage.setItem(DDG_RESENT_TO_EMAIL_KEY, newStatus)
}

function getFirstTimeStatus () {
  return sessionStorage.getItem(IS_FIRST_TIME)
}

function setFirstTimeStatus (newStatus) {
  sessionStorage.setItem(IS_FIRST_TIME, newStatus)
}

function getDigitallyEducatedStatus () {
  return sessionStorage.getItem(DIGITALLY_EDUCATED_KEY)
}

function setDigitallyEducatedStatus () {
  sessionStorage.setItem(DIGITALLY_EDUCATED_KEY, true)
}

function getSmsOrNoPhoneModalShownStatus () {
  return sessionStorage.getItem(SMS_OR_NO_PHONE_MODAL_SHOWN_KEY)
}

function setSmsOrNoPhoneModalShownStatus (newStatus) {
  sessionStorage.setItem(SMS_OR_NO_PHONE_MODAL_SHOWN_KEY, newStatus)
}

export default {
  HUB_SESSION_ID_KEY,
  HUB_SESSION_TYPE_KEY,
  BROCHURE_SENT_TO_EMAIL_KEY,
  MINI_LQ_SURVEY_ANSWERS_KEY,
  PATIENT_RESULTS_KEY,
  AUTHENTICATED_STATUS,
  UNAUTHENTICATED_STATUS,
  ELIGIBLE_STATUS: QUALIFIED_STATUS,
  INELIGIBLE_STATUS: UNQUALIFIED_STATUS,
  AMBASSADOR_LIST,
  AGE_FILTER_LIST,
  GENDER_FILTER_LIST,
  DDG_RESENT_TO_EMAIL_KEY,
  IS_FIRST_TIME,
  DIGITALLY_EDUCATED_KEY,
  SMS_OR_NO_PHONE_MODAL_SHOWN_KEY,
  isHubRoute,
  getRouteAuthenticationStatus,
  getHubSessionAuthenticationStatus,
  setHubSessionAuthenticationStatus,
  getHubSessionEligibilityStatus,
  getHubSessionId,
  setHubSessionId,
  getBrochureSentToEmailStatus,
  setBrochureSentToEmailStatus,
  getMiniLqAnswers,
  setMiniLqAnswers,
  getPatientResults,
  setPatientResults,
  getAmbassadorList,
  setAmbassadorList,
  getAgeFilterList,
  setAgeFilterList,
  getGenderFilterList,
  setGenderFilterList,
  getDDGReSentToEmailStatus,
  setDDGReSentToEmailStatus,
  getFirstTimeStatus,
  setFirstTimeStatus,
  getDigitallyEducatedStatus,
  setDigitallyEducatedStatus,
  getSmsOrNoPhoneModalShownStatus,
  setSmsOrNoPhoneModalShownStatus
}
