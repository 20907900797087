const surveySubmitMiniLqId1 = {
  'id': 1,
  'questions': {
    '1': {
      'id': 1,
      'text': 'Have you discussed the WATCHMAN Implant with your cardiologist?',
      'type': 'survey_start',
      'options': [
        {
          'id': 1,
          'text': 'Yes',
          'type': 'button',
          'group_name': null
        },
        {
          'id': 2,
          'text': 'Not yet',
          'type': 'button',
          'group_name': null
        }
      ]
    },
    '2': {
      'id': 2,
      'text': 'Great, do you mind sharing what you decided?',
      'options': [
        {
          'id': 3,
          'text': 'We have decided to move forward',
          'type': 'radio',
          'group_name': 'group_1'
        },
        {
          'id': 4,
          'text': 'I\'m still considering',
          'type': 'radio',
          'group_name': 'group_1'
        },
        {
          'id': 5,
          'text': 'My cardiologist said I\'m not a candidate',
          'type': 'radio',
          'group_name': 'group_1'
        }
      ]
    },
    '3': {
      'id': 3,
      'text': 'Thank you for sharing, what is your next step?',
      'options': [
        {
          'id': 6,
          'text': 'I have an appointment scheduled',
          'type': 'radio',
          'group_name': 'group_2'
        },
        {
          'id': 7,
          'text': 'I would like more information',
          'type': 'radio',
          'group_name': 'group_2'
        },
        {
          'id': 8,
          'text': 'I don\'t plan to move forward',
          'type': 'radio',
          'group_name': 'group_2'
        }
      ]
    },
    '4': {
      'id': 4,
      'text': 'How do you feel about getting the WATCHMAN Implant implanted?',
      'type': 'submit',
      'options': [
        {
          'id': 9,
          'text': 'I\'m excited!',
          'type': 'radio',
          'group_name': 'group_3'
        },
        {
          'id': 10,
          'text': 'It is what it is.',
          'type': 'radio',
          'group_name': 'group_3'
        },
        {
          'id': 11,
          'text': 'I am nervous',
          'type': 'radio',
          'group_name': 'group_3'
        }
      ]
    },
    '5': {
      'id': 5,
      'text': 'What kind of information are you looking for?',
      'type': 'submit',
      'options': [
        {
          'id': 12,
          'text': 'Is it covered by my insurance?',
          'type': 'radio',
          'group_name': 'group_4'
        },
        {
          'id': 13,
          'text': 'What does recovery look like?',
          'type': 'radio',
          'group_name': 'group_4'
        }
      ]
    },
    '6': {
      'id': 6,
      'text': 'Why are you not continuing on?',
      'type': 'submit',
      'options': [
        {
          'id': 14,
          'text': 'I do not think it will work well for me.',
          'type': 'radio',
          'group_name': 'group_5'
        },
        {
          'id': 15,
          'text': 'My insurance will not cover it.',
          'type': 'radio',
          'group_name': 'group_5'
        },
        {
          'id': 16,
          'text': 'My doctor strongly recommended against it.',
          'type': 'radio',
          'group_name': 'group_5'
        }
      ]
    }
  },
  'meta': {
    'initial_question': 1,
    'question_mapping': {
      '1': {
        'option_mapping': {
          '1': 2,
          '2': 3
        }
      },
      '2': {
        'option_mapping': {
          '3': 4,
          '4': 5,
          '5': 6
        }
      },
      '3': {
        'option_mapping': {
          '6': 4,
          '7': 5,
          '8': 6
        }
      },
      '4': {
        'option_mapping': {
          '9': null,
          '10': null,
          '11': null
        }
      },
      '5': {
        'option_mapping': {
          '12': null,
          '13': null
        }
      },
      '6': {
        'option_mapping': {
          '14': null,
          '15': null,
          '16': null
        }
      }
    }
  }
}

module.exports = surveySubmitMiniLqId1
