import axios from 'axios'
import language from '@/utils/language.config'
import { createRouter, createWebHistory } from 'vue-router'
import sessionHelper from '@/utils/storage/sessionStorage-helper'

// eslint-disable-next-line new-cap
const router = new createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      redirect: '/questionnaire'
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/questionnaire'
    },
    {
      path: '/register',
      name: language.routeNames.registerWatchman,
      component: () => import(/* webpackChunkName: "register-watchman" */ './components/register/LandingPage'),
      meta: {
        metis: 'SH-525501-AE',
        doesNotDigitallyEducate: true
      }
    },
    {
      path: '/register/confirmation',
      name: 'register-watchman-confirmation',
      component: () => import(/* webpackChunkName: "register-watchman-confirmation" */ './components/register/Confirmation'),
      meta: {
        metis: 'SH-525501-AE',
        doesNotDigitallyEducate: true
      }
    },
    {
      path: '/response-covid-19/:tempPatientId?',
      name: 'covid-19-response',
      component: () => import(/* webpackChunkName: "covid-19-response" */ './components/covid/LandingPage'),
      meta: {
        backToHome: true,
        metis: 'SH-787202-AB',
        pageName: 'Covid 19 response'
      }
    },
    {
      path: '/your-results/view-your-risks/:tempPatientId?',
      name: language.routeNames.viewYourRisks,
      component: () => import(/* webpackChunkName: "view-your-risks" */ './components/yourResults/viewYourRisks/LandingPage'),
      meta: {
        metis: 'SH-1641207-AB',
        pageName: 'know your risks',
        breadcrumbName: 'Know your risks',
        linkText: 'Get your personalized risk scores',
        isHubPage: true,
        parentNav: 'Your Results',
        noIdRedirectTo: language.routeNames.results
      }
    },
    {
      path: '/email/specialist/:tempPatientId?',
      name: language.routeNames.emailEducationSpecialistInternal,
      component: () => import(/* webpackChunkName: "email-education-specialist-internal" */ './components/common/emailEducationSpecialist/InternalEmailSpecialistPage'),
      meta: {
        backToHome: true,
        metis: 'SH-1249706-AA',
        pageName: 'Email a WATCHMAN Education Specialist',
        breadcrumbName: 'Email a WATCHMAN Education Specialist',
        doesNotDigitallyEducate: true,
        noIdRedirectTo: language.routeNames.emailEducationSpecialistExternal
      }
    },
    {
      path: '/email/specialist/confirmation',
      name: language.routeNames.emailEducationSpecialistConfirmationInternal,
      component: () => import(/* webpackChunkName: "email-confirmation-internal" */ './components/common/ConfirmationPage'),
      meta: {
        backToHome: true,
        metis: 'SH-1249706-AA',
        breadcrumbName: 'Email a WATCHMAN Education Specialist',
        confirmationHeader: language.email_education_specialist.confirmationHeader,
        confirmationMessage: language.email_education_specialist.confirmationMessage,
        doesNotDigitallyEducate: true,
        connectToSpecialistType: 'education'
      }
    },
    {
      path: '/email-specialist',
      name: language.routeNames.emailEducationSpecialistExternal,
      component: () => import(/* webpackChunkName: "email-education-specialist-external" */ './components/common/emailEducationSpecialist/ExternalEmailSpecialistPage'),
      meta: {
        backToHome: true,
        metis: 'SH-1249706-AA',
        doesNotDigitallyEducate: true
      }
    },
    {
      path: '/email-specialist/confirmation',
      name: language.routeNames.emailEducationSpecialistConfirmationExternal,
      component: () => import(/* webpackChunkName: "email-confirmation-external" */ './components/common/ConfirmationPage'),
      meta: {
        backToHome: true,
        metis: 'SH-1249706-AA',
        confirmationHeader: language.email_education_specialist.confirmationHeader,
        confirmationMessage: language.email_education_specialist.confirmationMessage,
        doesNotDigitallyEducate: true
      },
      props: {
        blueLineOnly: true
      }
    },
    {
      path: '/ambassador/confirmation',
      name: 'ambassadorConfirmation',
      component: () => import(/* webpackChunkName: "ambassador-confirmation" */ './components/common/ConfirmationPage'),
      meta: {
        backToHome: true,
        metis: 'SH-561505-AB',
        confirmationHeader: language.ambassador_page.confirmationHeader,
        confirmationMessage: language.ambassador_page.confirmationMessage,
        confirmationType: 'ambassador',
        doesNotDigitallyEducate: true
      }
    },
    {
      path: '/emailFriends/:tempPatientId?',
      name: language.routeNames.emailFriends,
      redirect: { name: language.routeNames.emailFriendsBrochure }
    },
    {
      path: '/email-friends-brochure/:tempPatientId?',
      name: language.routeNames.emailFriendsBrochure,
      component: () => import(/* webpackChunkName: "email-friends-brochure" */ './components/friends/EmailFriendsPreview'),
      meta: {
        backToHome: true,
        metis: 'SH-1265604-AA',
        breadcrumbName: 'Share with family & friends',
        doesNotDigitallyEducate: true
      },
      props: {
        introHeaderCopy: language.email_friends_family_page.brochure.introHeaderCopy,
        shareType: language.email_friends_family_page.brochure.shareType
      }
    },
    {
      path: '/emailFriendsConfirmation/:tempPatientId?',
      name: 'emailFriendsConfirmation',
      component: () => import(/* webpackChunkName: "email-friends-confirmation" */ './components/common/ConfirmationPage'),
      meta: {
        backToHome: true,
        metis: 'SH-1265604-AA',
        confirmationHeader: language.email_friends_family_page.brochure.confirmationHeader,
        confirmationMessage: language.email_friends_family_page.brochure.confirmationMessage,
        confirmationType: 'friendsBrochure',
        breadcrumbName: 'Thank you',
        doesNotDigitallyEducate: true,
        connectToSpecialistType: 'education'
      }
    },
    {
      path: '/emailProvider/:tempPatientId?',
      name: language.routeNames.emailProvider,
      component: () => import(/* webpackChunkName: "email-provider" */ './components/provider/SelectProvider'),
      props: true,
      meta: {
        pageName: 'share guide with your doctor',
        backToHome: true,
        metis: 'SH-797405-AA',
        breadcrumbName: 'Share your guide with your doctor',
        parentNav: 'Your Results',
        doesNotDigitallyEducate: true,
        noIdRedirectTo: language.routeNames.results
      }
    },
    {
      path: '/emailProviderConfirmation/:tempPatientId?',
      name: 'emailProviderConfirmation',
      component: () => import(/* webpackChunkName: "email-provider-confirmation" */ './components/common/ConfirmationPage'),
      meta: {
        backToHome: true,
        metis: 'SH-797405-AA',
        confirmationHeader: language.email_provider_page.confirmationHeader,
        confirmationMessage: language.email_provider_page.confirmationMessage,
        confirmationType: 'providerDdg',
        breadcrumbName: 'Share your guide with your doctor',
        pageName: 'share guide with your doctor',
        parentNav: 'Your Results',
        doesNotDigitallyEducate: true,
        connectToSpecialistType: 'education',
        noIdRedirectTo: language.routeNames.results
      }
    },
    {
      path: '/emailPreview/:tempPatientId?',
      name: language.routeNames.emailPreview,
      component: () => import(/* webpackChunkName: "email-preview" */ './components/provider/EmailProviderPreview'),
      props: true,
      meta: {
        breadcrumbName: 'Share your guide with your doctor',
        pageName: 'share guide with your doctor',
        backToPage: true,
        metis: 'SH-797405-AA',
        parentNav: 'Your Results',
        doesNotDigitallyEducate: true,
        noIdRedirectTo: language.routeNames.results
      }
    },
    {
      path: '/error/general',
      name: 'errorGeneral',
      component: () => import(/* webpackChunkName: "error-general" */ './components/common/errors/General'),
      meta: {
        metis: 'SH-531306-AB JUL2018',
        doesNotDigitallyEducate: true
      }
    },
    {
      path: '/careteam',
      name: 'careTeam',
      component: () => import(/* webpackChunkName: "care-team" */ './components/questionnaire/careteam/SinglePage'),
      meta: {
        metis: 'SH-531306-AK',
        pageName: 'assisted quiz',
        doesNotDigitallyEducate: true
      }
    },
    {
      path: '/careTeamAuth',
      name: 'careTeamAuth',
      component: () => import(/* webpackChunkName: "care-team-auth" */ './components/questionnaire/careteam/CareTeamAuth'),
      meta: {
        metis: 'SH-531306-AG',
        doesNotDigitallyEducate: true
      }
    },
    {
      path: '/consentOnly',
      name: 'consentOnly',
      component: () => import(/* webpackChunkName: "consent-only" */ './components/questionnaire/careteam/ConsentOnlyForm'),
      meta: {
        metis: 'SH-531306-AK',
        doesNotDigitallyEducate: true
      }
    },
    {
      path: '/consentOnlyConfirmation',
      name: 'consentOnlyConfirmation',
      component: () => import(/* webpackChunkName: "consent-only-confirmation" */ './components/common/ConfirmationPage'),
      meta: {
        backToHome: true,
        metis: 'SH-531306-AK',
        confirmationHeader: language.consent_only_page.confirmationHeader,
        confirmationMessage: language.consent_only_page.confirmationMessage,
        confirmationType: 'consentOnly',
        doesNotDigitallyEducate: true
      }
    },
    {
      path: '/careteamConfirmation',
      name: 'careteamConfirmation',
      component: () => import(/* webpackChunkName: "consent-only-confirmation" */ './components/common/ConfirmationPage'),
      meta: {
        backToHome: true,
        metis: 'SH-531306-AK',
        confirmationHeader: language.care_team_page.confirmationHeader,
        confirmationMessage: language.care_team_page.confirmationMessage,
        confirmationType: 'careTeam',
        doesNotDigitallyEducate: true
      }
    },
    {
      path: '/campaigns/testimonials',
      name: 'campaignTestimonials',
      redirect: { name: language.routeNames.patientStories },
      component: () => import(/* webpackChunkName: "campaign-testimonials" */ './components/campaign/Testimonials'),
      meta: {
        metis: 'SH-594504-AB',
        subHeader: {
          title: 'What it\'s like: Real WATCHMAN Implant Stories',
          content: '<div>If you\'re living with atrial fibrillation (AFib) not caused by a heart valve problem and considering the WATCHMAN Implant as an alternative to blood thinners, you might be wondering: <strong>What\'s the WATCHMAN Implant procedure really like?</strong> It\'s normal to have questions, and sometimes it helps to hear from people who\'ve been in your shoes. That\'s where the stories below come in.</div>\n' +
            '<div>The people featured come from all walks of life, but they have one thing in common: they chose the WATCHMAN Implant as a permanent solution to help reduce the risk of stroke without the risk of bleeding that can come with the long-term use of blood-thinners.</div>',
          showWatchmanImg: true
        },
        doesNotDigitallyEducate: true
      }
    },
    {
      path: '/campaigns/procedure-support',
      name: 'campaignProcedureSupport',
      component: () => import(/* webpackChunkName: "campaign-procedure-support" */ './components/campaign/ProcedureSupport/ProcedureSupport'),
      meta: {
        metis: 'SH-594503-AB',
        subHeader: {
          title: 'Welcome to the WATCHMAN Implant family!',
          content: 'Congratulations! You\'ve taken a big step towards a life without blood thinners. Here are just a few things to keep in mind.',
          showWatchmanImg: true
        },
        doesNotDigitallyEducate: true
      }
    },
    {
      path: '/campaigns/decisions-phase',
      name: 'campaignDecisionsPhase',
      component: () => import(/* webpackChunkName: "campaign-decisions-phase" */ './components/campaign/DecisionsPhase/DecisionsPhaseMain'),
      meta: {
        metis: 'SH-594502-AC',
        subHeader: {
          title: 'Life Without Blood Thinners? It\'s Possible.',
          content: 'AFib doesn\'t have to mean a lifetime of blood thinners. The WATCHMAN Implant is a one‑time, minimally invasive procedure that reduces stroke risk and bleeding worry for life.',
          showWatchmanImg: true
        },
        doesNotDigitallyEducate: true
      }
    },
    {
      path: '/campaigns/consideration-phase',
      name: 'campaignConsiderationPhase',
      component: () => import(/* webpackChunkName: "campaign-consideration-phase" */ './components/campaign/ConsiderationPhase/ConsiderationPhaseMain'),
      meta: {
        metis: 'SH-561111-AC',
        subHeader: {
          title: 'The WATCHMAN Implant Difference',
          content:
            '<div>The WATCHMAN Implant is a one-time, minimally invasive procedure for people with atrial fibrillation not caused by a heart valve problem (also known as non-valvular AFib) who need an alternative to blood thinners.</div>\n' +
            '<div>Non-valvular Afib can mean a lifetime of blood thinners. It can also mean a lifetime of worry about issues like bleeds and falls. More than 150,000 people have left blood thinners behind with the WATCHMAN Implant.</div>\n' +
            '<div>In a clinical trial, 96% of people were able to stop taking blood thinners just 45 days after the WATCHMAN Implant procedure. <sup>1</sup></div>',
          showWatchmanImg: true
        },
        doesNotDigitallyEducate: true
      }
    },
    {
      path: '/campaigns/:campaignId',
      name: 'listeningQuestions',
      component: () => import(/* webpackChunkName: "listening-questions" */ './components/questionnaire/reengagement/Campaign')
    },
    {
      path: '/questionnaire/:sfLeadId?',
      name: language.routeNames.questionnaire,
      component: () => import(
        /* webpackChunkName: "questionnaire" */
        /* webpackPreload: true */ './components/questionnaire/eligibility/Survey'),
      meta: {
        metis: 'SH-531306-AK, SH-1613109-AA',
        pageName: 'survey',
        doesNotDigitallyEducate: true
      },
      props: (route) => {
        if (route.query.invalidRoute !== undefined) {
          return { invalidRoute: true }
        } else {
          return {
            invalidRoute: !(route.redirectedFrom === undefined ||
              route.redirectedFrom.fullPath === '/' ||
              route.redirectedFrom.fullPath === '/questionnaire' ||
              route.redirectedFrom.fullPath.toString().substring(0, 2) === '/?')
          }
        }
      }
    },
    {
      path: '/questionnaire/resubmission/:tempPatientId?',
      name: language.routeNames.questionnaireResubmission,
      component: () => import(
        /* webpackChunkName: "questionnaire-resubmission" */
        /* webpackPreload: true */ './components/questionnaire/resubmission/ResubmissionLandingPage'),
      meta: {
        metis: 'SH-531306-AK',
        isHubPage: true,
        doesNotDigitallyEducate: true
      },
      beforeEnter: (to, from, next) => {
        if (!sessionHelper.getHubSessionId()) {
          // if no id in session redirect to original questionnaire
          next(language.routeNames.questionnaire)
        } else {
          next()
        }
      }
    },
    {
      path: '/unqualified/:tempPatientId?',
      name: 'unqualified',
      meta: {
        metis: 'to be set on results landing page (unqualified route)',
        doesNotDigitallyEducate: true,
        pageName: 'unqualified results'
      },
      redirect: { name: language.routeNames.results }
    },
    {
      path: '/results/:tempPatientId?',
      name: language.routeNames.results,
      component: () => import(/* webpackChunkName: "results" */ './components/results/LandingPage'),
      meta: {
        metis: null,
        qualifiedMetis: 'SH-1765907-AA, SH-1283307-AB, SH-594103-AB',
        unauthenticatedMetis: 'SH-1765907-AA, SH-594103-AB',
        unqualifiedMetis: 'SH-1302406-AA, SH-1283307-AB, SH-594103-AB',
        pageName: 'your results',
        breadcrumbName: 'Home',
        isHubPage: true,
        doesNotDigitallyEducate: true,
        parentNav: 'Home'
      }
    },
    {
      path: '/preview/guide/:tempPatientId?',
      name: language.routeNames.previewGuide,
      component: () => import(/* webpackChunkName: "preview-guide" */ './components/preview/guide/LandingPage'),
      meta: {
        metis: null,
        pageName: 'your results',
        breadcrumbName: 'Your doctor discussion guide',
        linkText: 'View your personalized doctor discussion guide',
        isHubPage: true,
        qualifiedMetis: 'SH-1882508-AA',
        unqualifiedMetis: 'SH-942001-AB',
        doesNotDigitallyEducate: true,
        parentNav: 'Your Results',
        noIdRedirectTo: language.routeNames.results
      }
    },
    {
      path: '/preview/brochure/:tempPatientId?',
      name: language.routeNames.previewBrochure,
      component: () => import(/* webpackChunkName: "preview-brochure" */ './components/preview/brochure/LandingPage'),
      meta: {
        metis: 'SH-1255108-AB',
        pageName: 'brochure preview',
        breadcrumbName: 'The WATCHMAN Implant brochure',
        isHubPage: true,
        doesNotDigitallyEducate: true
      }
    },
    {
      path: '/about/how-does-it-work/:tempPatientId?',
      name: language.routeNames.howDoesItWork,
      component: () => import(/* webpackChunkName: "how-does-it-work" */ './components/about/howDoesItWork/LandingPage'),
      meta: {
        metis: 'SH-1254406-AC',
        pageName: 'how does it work?',
        breadcrumbName: 'How does it work?',
        linkText: 'How does the WATCHMAN Implant work?',
        isHubPage: true,
        parentNav: 'About'
      }
    },
    {
      path: '/about/is-it-safe/:tempPatientId?',
      name: language.routeNames.isItSafe,
      component: () => import(/* webpackChunkName: "is-it-safe" */ './components/about/isItSafe/LandingPage'),
      meta: {
        metis: 'SH-1254210-AC',
        pageName: 'is it safe?',
        breadcrumbName: 'Is it safe?',
        linkText: 'Is the WATCHMAN Implant safe?',
        isHubPage: true,
        parentNav: 'About'
      }
    },
    {
      path: '/about/why-choose-watchman/:tempPatientId?',
      name: language.routeNames.whyChooseWatchman,
      component: () => import(/* webpackChunkName: "why-choose-watchman" */ './components/about/whyChooseWatchman/LandingPage'),
      meta: {
        metis: 'SH-1253907-AC',
        pageName: 'why choose the WATCHMAN Implant?',
        breadcrumbName: 'Why choose the WATCHMAN Implant?',
        isHubPage: true,
        parentNav: 'About'
      }
    },
    {
      path: '/about/talk-to-doctor/:tempPatientId?',
      name: language.routeNames.talkToDoctor,
      component: () => import(/* webpackChunkName: "talk-to-doctor" */ './components/about/talkToDoctor/LandingPage'),
      meta: {
        metis: 'SH-1796705-AA',
        pageName: 'Talk to your doctor',
        breadcrumbName: 'Prepare to talk to your doctor',
        isHubPage: true,
        parentNav: 'About'
      }
    },
    {
      path: '/patient-stories/see-patient-stories/:tempPatientId?',
      alias: '/campaigns/testimonials',
      name: language.routeNames.patientStories,
      component: () => import(/* webpackChunkName: "see-patient-stories" */ './components/patientStories/seePatientStories/LandingPage'),
      meta: {
        metis: 'SH-1253609-AD',
        pageName: 'patient stories',
        breadcrumbName: 'Patient stories',
        isHubPage: true,
        parentNav: 'Patient Stories'
      }
    },
    {
      path: '/patient-stories/ambassadors/:tempPatientId?',
      name: language.routeNames.ambassadorLibrary,
      component: () => import(/* webpackChunkName: "patient-stories-ambassadors-library" */ './components/patientStories/ambassadorsLibrary/LandingPage'),
      meta: {
        metis: 'SH-1402608-AA',
        pageName: 'Ambassadors library',
        breadcrumbName: 'Connect with a WATCHMAN Ambassador',
        linkText: 'Meet WATCHMAN Ambassadors',
        isHubPage: true,
        doesNotDigitallyEducate: true,
        parentNav: 'Patient Stories'
      }
    },
    {
      path: '/phoneconfirmation/:connectionObjectId',
      name: language.routeNames.ambassadorMeetingChoice,
      component: () => import(/* webpackChunkName: "patient-stories-ambassador-meeting-choice" */ './components/ambassador/ambassadorMeetingChoice/LandingPage'),
      meta: {
        metis: 'SH-1403106-AD',
        isHubPage: false,
        doesNotDigitallyEducate: true,
        pageName: 'Ambassador meeting choice',
        parentNav: 'Patient Stories'
      }
    },
    {
      path: '/patient-stories/ambassador/profile/:ambassadorId/:tempPatientId?',
      name: language.routeNames.ambassadorProfile,
      component: () => import(/* webpackChunkName: "patient-stories-ambassador-profile" */ './components/patientStories/ambassadorProfile/LandingPage'),
      meta: {
        metis: 'SH-1402508-AB',
        doesNotDigitallyEducate: true,
        pageName: 'Ambassador profile',
        isHubPage: true,
        parentNav: 'Patient Stories'
      }
    },
    {
      path: '/patient-stories/ambassador/connect/email/:ambassadorId/:tempPatientId?',
      name: language.routeNames.ambassadorSendAnEmail,
      component: () => import(/* webpackChunkName: "patient-stories-ambassador-send-an-email" */ './components/patientStories/ambassadorProfile/sendAnEmail/LandingPage'),
      meta: {
        metis: 'SH-1403106-AD',
        pageName: 'Ambassador send an email',
        breadcrumbName: 'Send an Email',
        isHubPage: true,
        doesNotDigitallyEducate: true,
        parentNav: 'Patient Stories'
      }
    },
    {
      path: '/patient-stories/ambassador/connect/phone/:ambassadorId/:tempPatientId?',
      name: language.routeNames.ambassadorRequestAPhoneCall,
      component: () => import(/* webpackChunkName: "patient-stories-ambassador-request-a-phone-call" */ './components/patientStories/ambassadorProfile/requestAPhoneCall/LandingPage'),
      meta: {
        metis: 'SH-1403106-AD',
        pageName: 'Ambassador request a phone call',
        breadcrumbName: 'Request a phone call',
        isHubPage: true,
        doesNotDigitallyEducate: true,
        parentNav: 'Patient Stories'
      }
    },
    {
      path: '/appointment-preparation',
      name: language.routeNames.appointmentPreparation,
      component: () => import(/* webpackChunkName: "appointment-preparation" */ './components/appointmentPreparation/LandingPage'),
      meta: {
        metis: 'SH-1496208-AA',
        pageName: 'appointment preparation',
        isHubPage: true
      }
    },
    {
      path: '/resources/video-library/:tempPatientId?',
      name: language.routeNames.videoLibrary,
      component: () => import(/* webpackChunkName: "video-library" */ './components/resources/videoLibrary/LandingPage'),
      meta: {
        metis: 'SH-1578202-AA',
        pageName: 'video library',
        isHubPage: true,
        breadcrumbName: 'Video library',
        linkText: 'Learn from Dr. Kanj, MD, Cardiologist',
        parentNav: 'Resources'
      }
    },
    {
      path: '/resources/become-an-ambassador/:tempPatientId?',
      name: language.routeNames.becomeAnAmbassador,
      component: () => import(/* webpackChunkName: "video-library" */ './components/resources/becomeAmbassador/LandingPage'),
      meta: {
        metis: 'SH-1902306-AA',
        pageName: 'become an ambassador',
        isHubPage: true,
        breadcrumbName: 'Become a WATCHMAN Ambassador',
        parentNav: 'Resources'
      }
    },
    {
      path: '/about/what-to-expect-procedure/:tempPatientId?',
      name: language.routeNames.whatToExpect,
      component: () => import(/* webpackChunkName: "what-to-expect" */ './components/about/whatToExpect/LandingPage'),
      meta: {
        metis: 'SH-1568405-AA',
        pageName: 'what to expect with the procedure',
        breadcrumbName: 'What to expect with the procedure',
        isHubPage: true,
        parentNav: 'About'
      }
    },
    {
      path: '/about/understanding-cost/:tempPatientId?',
      name: language.routeNames.understandingCost,
      component: () => import(/* webpackChunkName: "understanding-cost" */ '@/components/about/understandingCost/LandingPage'),
      meta: {
        metis: 'SH-1638906-AA',
        pageName: 'understanding cost',
        breadcrumbName: 'Understanding cost',
        linkText: 'Understanding cost of the WATCHMAN Implant',
        parentNav: 'About'
      }
    },
    {
      path: '/referral',
      name: language.routeNames.referral,
      redirect: () => {
        window.location.href = '/pdf/patient/refer'
      }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return { selector: to.hash }
    } else {
      return { top: 0 }
    }
  }
})

function trackHubSession (to) {
  let oldAuthenticationStatus = sessionHelper.getHubSessionAuthenticationStatus()
  // current authentication status based on presence of sessionId
  let newAuthenticationStatus = sessionHelper.getRouteAuthenticationStatus()

  if (newAuthenticationStatus !== oldAuthenticationStatus) {
    sessionHelper.setHubSessionAuthenticationStatus(newAuthenticationStatus)

    let dataLayerObject = {
      event: 'authenticationEvent',
      userAuthentication: `${newAuthenticationStatus}`
    }

    if (newAuthenticationStatus === sessionHelper.AUTHENTICATED_STATUS) {
      const intervalId = setInterval(() => {
        if (sessionHelper.getHubSessionEligibilityStatus() !== null) {
          dataLayerObject['CD70'] = sessionHelper.getHubSessionEligibilityStatus()
          clearInterval(intervalId)
        }
      }, 500)
    }
    window.dataLayer.push(dataLayerObject)
  }
}

function handleDigitallyEducatedStatus (toRoute) {
  if (!toRoute.meta.doesNotDigitallyEducate) {
    if (!sessionHelper.getDigitallyEducatedStatus() && sessionHelper.getHubSessionId()) {
      sessionHelper.setDigitallyEducatedStatus()
      axios.get(`/api/digitally-educated/${sessionHelper.getHubSessionId()}`)
    }
  }
}

router.beforeEach((to, from, next) => {
  if (to.params.tempPatientId) {
    const currentSessionId = sessionHelper.getHubSessionId()
    const newSessionId = to.params.tempPatientId
    let newRoute = {}
    newRoute.name = to.name
    newRoute.query = to.query
    delete to.params.tempPatientId
    newRoute.params = to.params

    router.replace(newRoute)
    if (newSessionId && (currentSessionId !== newSessionId)) {
      sessionHelper.setHubSessionId(newSessionId)
    }
  } else {
    if (to.meta.noIdRedirectTo && !sessionHelper.getHubSessionId()) {
      router.replace({ name: to.meta.noIdRedirectTo })
    }
  }
  handleDigitallyEducatedStatus(to)

  next()
})

router.afterEach((to, from) => {
  if (sessionHelper.isHubRoute(to)) {
    trackHubSession(to)
  }
})

export default router
