import 'core-js/stable'
import '@fortawesome/fontawesome-free/css/all.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import 'vuetify/styles'
import patientId from './plugins/patientId'
import nullCheck from '@/plugins/nullCheck'
import mocks from './mocks/mocks.js'
import VueScrollTo from 'vue-scrollto'
import VueSmoothScroll from 'vue3-smooth-scroll'
import bus from '@/eventBus'
import '@/utils/vee-validate.validator'

import { createGtm } from '@gtm-support/vue-gtm'
import VCalendar from 'v-calendar'
import 'v-calendar/style.css'

// import Vue plugin
import VueSvgInlinePlugin from 'vue-svg-inline-plugin'

// import polyfills for IE if you want to support it
import 'vue-svg-inline-plugin/src/polyfills'

const isProd = process.env['NODE_ENV'] === 'production'
const useBackend = process.env['VUE_APP_USE_BACKEND'] === 'true'

if (!isProd && !useBackend) {
  mocks.initializeMocks()
}

const app = createApp(App)
app.use(VueScrollTo)
app.use(VueSmoothScroll, {
  offset: -100
})
app.use(patientId)
app.use(nullCheck)
app.use(VCalendar, {
  componentPrefix: 'vc'
})
app.use(createGtm({
  id: process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID,
  vueRouter: router
}))
app.use(VueSvgInlinePlugin)

const fixIdScrolling = {
  watch: {
    $route (to, from) {
      let idToScrollTo = this.$router.currentRoute.hash
      // let idToScrollTo = location.hash
      this.$nextTick(() => {
        setTimeout(() => {
          if (idToScrollTo) {
            idToScrollTo = idToScrollTo.split(':')[0]
            this.$smoothScroll({
              scrollTo: this.$refs.myEl,
              duration: 1000,
              offset: -50
            })
          }
        }, 1000)
      })
    }
  }
}

app.config.globalProperties.bus = bus
app.use(router)
app.use(vuetify)
app.mixin(fixIdScrolling)
app.mount('#app')
