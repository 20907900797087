import bus from '../eventBus'
import images from './dam.images.repo.config'
import language from '../utils/language.config'
import sessionHelper from './storage/sessionStorage-helper'

const getEmailSpecialistRoute = currentRoute => {
  let newRoute = {}
  newRoute.query = {
    originatingRoute: currentRoute.name
  }
  if (currentRoute.params.ambassadorId) {
    newRoute.query.ambassadorId = currentRoute.params.ambassadorId
  }
  if (sessionHelper.getHubSessionId()) {
    newRoute.name = language.routeNames.emailEducationSpecialistInternal
    newRoute.query.type = language.routeNames.emailEducationSpecialistInternal
  } else {
    newRoute.name = language.routeNames.emailEducationSpecialistExternal
  }
  return newRoute
}

const emailSpecialistType = (typeParam) => {
  let type

  switch (typeParam) {
    case 'covid-19-response':
      type = 'covid'
      break

    case language.routeNames.results:
    case language.routeNames.previewGuide:
    case language.routeNames.previewBrochure:
    case language.routeNames.ambassadorLibrary:
    case language.routeNames.ambassadorProfile:
    case language.routeNames.ambassadorSendAnEmail:
    case language.routeNames.ambassadorMeetingChoice:
    case language.routeNames.ambassadorRequestAPhoneCall:
    case language.routeNames.emailPreview:
    case language.routeNames.emailFriends:
    case language.routeNames.emailProvider:
    case 'emailConfirmation':
    case language.routeNames.emailEducationSpecialistInternal:
    case 'emailEducationSpecialistConfirmation':
    case language.routeNames.emailEducationSpecialistExternal:
    case 'emailEducationSpecialistExternalConfirmation':
      type = 'qualified'
      break

    case 'unqualified':
      type = 'unqualified'
      break

    default:
      type = 'other'
      break
  }
  return type.toUpperCase()
}

const formatDoctorName = (name) => {
  return 'Dr. ' + name
}

const goBackInBrowserHistory = (router, pages) => {
  router.go(-pages)
}

const isLoadingClass = (loading) => {
  return (loading) ? 'hidden' : ''
}

const linker = function (route) {
  return '/' + route + '/' + sessionHelper.getHubSessionId()
}

const phoneLinker = (phoneNumber) => {
  return 'tel:+' + phoneNumber
}

const phoneLink = (phoneNumber) => {
  return '<a href="' + phoneLinker(phoneNumber) + '">' + phoneNumber + '</a>'
}

const emailLink = (emailAddress, linkText) => {
  if (isEmailAddress(emailAddress)) {
    linkText = linkText || emailAddress
    return `<a href="mailto:${emailAddress}">${linkText}</a>`
  }
  return ''
}

const isEmailAddress = (str) => {
  const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
  return pattern.test(str)
}

const stripHtmlTags = (str) => {
  return str.replace(/<\/?[^>]+(>|$)/g, '')
}

const strToLower = (str) => {
  /* eslint-disable */
  return !!str ? str.toLowerCase() : null
  /* eslint-enable */
}

const isDateValid = (date) => {
  // Assumes date is "mm/dd/yyyy"
  if (!/^\d\d\/\d\d\/\d\d\d\d$/.test(date)) {
    return false
  }
  const parts = date.split('/').map((p) => parseInt(p, 10))
  parts[0] -= 1
  const d = new Date(parts[2], parts[0], parts[1])
  return d.getMonth() === parts[0] && d.getDate() === parts[1] && d.getFullYear() === parts[2]
}
const MIN_DATE = '01/01/1701'
const MAX_DATE = '12/31/3999'

const isInValidDateRange = (date) => {
  const theDate = new Date(date)
  const minDate = new Date(MIN_DATE)
  const maxDate = new Date(MAX_DATE)

  return theDate > minDate && theDate < maxDate
}

const calculateAge = (date) => {
  if (!date) {
    return 'Unknown'
  }
  let age = new Date(date)
  let today = new Date()
  let thisYear = 0
  if (today.getMonth() < age.getMonth()) {
    thisYear = 1
  } else if ((today.getMonth() === age.getMonth()) && today.getDate() < age.getDate()) {
    thisYear = 1
  }
  const total = today.getFullYear() - age.getFullYear() - thisYear
  if (total < 1) {
    return '<1'
  } else {
    return total
  }
}

const isPhoneInputFilled = (value) => {
  return value.length === '+1 (XXX) XXX-XXXX'.length
}

const joinArray = (array, separator = ', ') => {
  return array.reduce((s, x, i) => s + (i > 0 ? separator : '') + (x == null ? '' : x), '')
}

const getAnswerText = (question, answers) => {
  if (!Array.isArray(answers)) {
    answers = [answers]
  }

  let answersText = []
  Object.entries(question.question_options).forEach(([key, option]) => {
    Object.entries(answers).forEach(([key, answer]) => {
      if (option.id === answer) {
        answersText.push(option.option_text)
      }
    })
  })
  return answersText
}

const capitalizeStr = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const capitalizeLowerStr = (str) => {
  return str[0].toUpperCase() + str.substring(1).toLowerCase()
}

const surveyAlternative = 'sng-964'

const getSurveyNumber = (route) => {
  if (route.query && route.query.alt === surveyAlternative) {
    return 14000
  }
  return 1000
}

const isEligibilityAbAltVersion = (route) => {
  return route.query.survey === surveyAlternative
}

const logError = (error) => {
  /* eslint no-console: ["error", { allow: ["error"] }] */
  console.error('Application error: ' + error)
}

const possessiveProperNoun = (noun) => {
  if (noun) {
    return noun.slice(-1).toLowerCase() === 's' ? `${noun}'` : `${noun}'s`
  } else {
    return 'Unknown'
  }
}

const joinValidationRules = (defaultRules, rules) => {
  return rules ? defaultRules + '|' + rules : defaultRules
}

const getCookieValue = (name) => {
  return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
}

const plural = (string, number) => {
  if (number === '<1') {
    return string
  }
  let plural = (number === null || isNaN(number) || number > 1) ? 's' : ''
  return string + plural
}

const newLinesToBreaks = (string) => {
  if (string !== null) {
    if (string.includes('\n') || string.includes('\r')) {
      // eslint-disable-next-line no-control-regex
      return string.replace(new RegExp('\r?\n', 'g'), '<br />')
    }
  }
  return string
}

const getAmbassadorTitleName = (ambassador) => {
  if (!ambassador || (!ambassador.firstName && !ambassador.lastName)) {
    return 'No Name'
  }
  if (ambassador.firstName !== '' && ambassador.lastName !== '') {
    return ambassador.firstName + ' ' + ambassador.lastName.substring(0, 1) + '.'
  } else if (ambassador.firstName !== '') {
    return ambassador.firstName
  } else {
    return 'No Name'
  }
}

const getAmbassadorProfilePhoto = (url) => {
  if (isValidUrl(url)) {
    if (isImgUrl(url)) {
      return url
    }
  }
  return images.patient_ambassadors.profile.photo_coming_soon
}

const isValidUrl = urlString => {
  const urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
    '(\\#[-a-z\\d_]*)?$', 'i') // validate fragment locator
  return !!urlPattern.test(urlString)
}

function isImgUrl (url) {
  return /\.(jpg|jpeg|png|webp|avif|gif)$/.test(url)
}

function stripNewLinesAndSpaces (string) {
  return string.replace(/\s+/g, ' ')
}

function arrayBufferToBase64 (bufferArray) {
  let binary = ''
  let bytes = new Uint8Array(bufferArray)
  let length = bytes.byteLength
  for (let i = 0; i < length; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

function setScrollButtonFadeOut (pageClass) {
  if (typeof document.getElementsByClassName(pageClass)[0] === 'undefined') {
    return setTimeout(() => {
      setScrollButtonFadeOut(pageClass)
    }, 200)
  }
  const value = document.getElementsByClassName(pageClass)[0].getBoundingClientRect().height
  if (value === 0) {
    setTimeout(() => {
      setScrollButtonFadeOut(pageClass)
    }, 200)
  } else {
    return value
  }
}

function accordionNavBlockObserver (entries) {
  entries.forEach(entry => {
    let intersectRatio = entry.intersectionRatio
    if (intersectRatio > accordionNavBlockThreshold()) {
      bus.$emit('update selected nav item', entry.target.className.split(' ').at(-1))
    }
  })
}

function accordionNavBlockThreshold () {
  return window.innerHeight > 850 ? 0.65 : 0.35
}

function scrollToTargetById (targetElementId, buffer = 10) {
  let element = document.getElementById(targetElementId)
  let headerOffset = 85
  let elementPosition = element.getBoundingClientRect().top
  let offsetPosition = elementPosition + window.scrollY - (headerOffset + buffer)

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth'
  })
}

function scrollToTargetByClassName (targetElementClassName, buffer = 10) {
  let element = document.getElementsByClassName(targetElementClassName)[0]
  let headerOffset = 85
  let elementPosition = element.getBoundingClientRect().top
  let offsetPosition = elementPosition + window.scrollY - (headerOffset + buffer)

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth'
  })
}

function showPhoneModalsByRoute (route) {
  return route && route.name !== language.routeNames.registerWatchman
}

function showInsuranceCarrier (primaryInsurance) {
  return !(primaryInsurance !== 'Commercial Insurance Plan' && primaryInsurance !== 'Health Insurance Exchange / ACA')
}

function showCommunicationIcon (ambassador, connection) {
  return ambassador?.waysToConnect && ambassador.waysToConnect.includes(connection) && ambassador?.levelOfAdvocate === 'Ambassador'
}

export default {
  getEmailSpecialistRoute,
  emailSpecialistType,
  formatDoctorName,
  goBackInBrowserHistory,
  isLoadingClass,
  linker,
  phoneLink,
  emailLink,
  isEmailAddress,
  phoneLinker,
  capitalizeStr,
  capitalizeLowerStr,
  strToLower,
  stripHtmlTags,
  isDateValid,
  isInValidDateRange,
  calculateAge,
  isPhoneInputFilled,
  joinArray,
  getAnswerText,
  isEligibilityAbAltVersion,
  getSurveyNumber,
  surveyAlternative,
  logError,
  possessiveProperNoun,
  joinValidationRules,
  getCookieValue,
  MIN_DATE,
  MAX_DATE,
  plural,
  newLinesToBreaks,
  getAmbassadorTitleName,
  getAmbassadorProfilePhoto,
  isValidUrl,
  isImgUrl,
  stripNewLinesAndSpaces,
  arrayBufferToBase64,
  setScrollButtonFadeOut,
  accordionNavBlockObserver,
  accordionNavBlockThreshold,
  showPhoneModalsByRoute,
  scrollToTargetById,
  scrollToTargetByClassName,
  showInsuranceCarrier,
  showCommunicationIcon
}
