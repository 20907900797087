
export default {
  install (app) {
    app.config.globalProperties.$nullCheck = (val) => {
      if (val === null || typeof val === 'undefined') {
        return ''
      } else {
        return val
      }
    }
  }
}
