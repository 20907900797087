import graphicBrochureCover from '@/assets/images/graphic-brochure-cover.png'
import graphicDayOfProcedureStep1 from '@/assets/images/graphic-what-to-expect-day-of-procedure-step-1.svg'
import graphicDayOfProcedureStep2 from '@/assets/images/graphic-what-to-expect-day-of-procedure-step-2.svg'
import graphicDayOfProcedureStep3 from '@/assets/images/graphic-what-to-expect-day-of-procedure-step-3.svg'
import graphicDayOfProcedureStep4 from '@/assets/images/graphic-what-to-expect-day-of-procedure-step-4.svg'
import graphicDDGUnqualified from '@/assets/images/graphic-ddg-unqualified.png'
import graphicDiscussionGuide from '@/assets/images/graphic-discussion-guide.png'
import graphicHeartLaa from '@/assets/images/graphic-heart-laa.png'
import graphicKnowYourRisks from '@/assets/images/graphic-know-your-risks.png'
import graphicLaaWithImplant from '@/assets/images/graphic-laa-with-implant.png'
import graphicPhysicianGenericHeadshot from '@/assets/images/graphic-physician-generic-headshot.svg'
import graphicPreviewDDG from '@/assets/images/graphic-ddg-preview.png'
import graphicPreviewDDGEvolution from '@/assets/images/graphic-ddg-preview-evolution.png'
import graphicQuarterWithWm from '@/assets/images/graphic-quarter-with-wm.png'
import graphicSpeedyGrandson from '@/assets/images/graphic-speedy-grandson.png'
import graphicVideoThumbnailBackground from '@/assets/images/graphic-video-thumbnail-background.png'
import graphicWomanTalkingWithDoctor from '@/assets/images/graphic-woman-talking-with-doctor.png'
import graphicMosaicPicturesOrangeBoxArrow from '@/assets/images/graphic-mosaic-pictures-orange-box-arrow.svg'
import graphicMosaicPicturesDesktop1 from '@/assets/images/graphic-mosaic-pictures-desktop-1.png'
import graphicMosaicPicturesDesktop2 from '@/assets/images/graphic-mosaic-pictures-desktop-2.png'
import graphicMosaicPicturesDesktop3 from '@/assets/images/graphic-mosaic-pictures-desktop-3.png'
import graphicMosaicPicturesDesktop4 from '@/assets/images/graphic-mosaic-pictures-desktop-4.png'
import graphicMosaicPicturesDesktop5 from '@/assets/images/graphic-mosaic-pictures-desktop-5.png'
import graphicMosaicPicturesDesktop6 from '@/assets/images/graphic-mosaic-pictures-desktop-6.png'
import graphicMosaicPicturesMobile from '@/assets/images/graphic-mosaic-pictures-mobile.png'
import graphicBecomeAmbassadorBannerMobile from '@/assets/images/graphic-become-ambassador-banner-mobile.png'
import graphicBecomeAmbassadorBannerTablet from '@/assets/images/graphic-become-ambassador-banner-tablet.png'
import graphicBecomeAmbassadorBannerDesktop from '@/assets/images/graphic-become-ambassador-banner-desktop.png'

import icon24Hours from '@/assets/images/icon-24-hrs.svg'
import icon300kLivesChanged from '@/assets/images/icon-300k-lives.svg'
import icon3people from '@/assets/images/icon-people-3x.svg'
import iconArrow from '@/assets/images/icon-arrow.svg'
import iconArrowCircle from '@/assets/images/icon-arrow-circle.svg'
import iconArrowCircleFilled from '@/assets/images/icon-arrow-circle-filled.svg'
import iconArrowCircleOutlined from '@/assets/images/icon-arrow-circle-outlined.svg'
import iconBirthdayCake from '@/assets/images/icon-birthday-cake.svg'
import iconBleeding from '@/assets/images/icon-bleeding.svg'
import iconBloodDrop from '@/assets/images/icon-blood-drop.svg'
import iconBloodThinners from '@/assets/images/icon-blood-thinners.svg'
import iconBrowserMouse from '@/assets/images/icon-browser-mouse.svg'
import iconCaret from '@/assets/images/icon-caret.svg'
import iconChatFacebook from '@/assets/images/icon-chat-facebook.svg'
import iconChatFilled from '@/assets/images/icon-chat-filled.svg'
import iconChatOutlined from '@/assets/images/icon-chat-outlined.svg'
import iconCheck from '@/assets/images/icon-check.svg'
import iconCheckHeavy from '@/assets/images/icon-check-heavy.svg'
import iconChecklist from '@/assets/images/icon-checklist.svg'
import iconCheckmarkCircle from '@/assets/images/icon-checkmark-circle.svg'
import iconCheckScallop from '@/assets/images/icon-check-scallop.svg'
import iconCircleMinus from '@/assets/images/icon-circle-minus.svg'
import iconCircleNo from '@/assets/images/icon-circle-no.svg'
import iconCircleNumberFour from '@/assets/images/icon-circle-number-4.svg'
import iconCircleNumberOne from '@/assets/images/icon-circle-number-1.svg'
import iconCircleNumberOpaqueFour from '@/assets/images/icon-circle-number-opaque-4.svg'
import iconCircleNumberOpaqueThree from '@/assets/images/icon-circle-number-opaque-3.svg'
import iconCircleNumberOpaqueTwo from '@/assets/images/icon-circle-number-opaque-2.svg'
import iconCircleNumberThree from '@/assets/images/icon-circle-number-3.svg'
import iconCircleNumberTwo from '@/assets/images/icon-circle-number-2.svg'
import iconCirclePlus from '@/assets/images/icon-circle-plus.svg'
import iconClipboard from '@/assets/images/icon-clipboard.svg'
import iconClock from '@/assets/images/icon-clock.svg'
import iconClockArrowCircular from '@/assets/images/icon-clock-arrow-circular.svg'
import iconCloseX from '@/assets/images/icon-close-x.svg'
import iconContactHeadphones from '@/assets/images/icon-contact-headphones.svg'
import iconCustomerServiceRep from '@/assets/images/icon-customer-service-rep.svg'
import iconDietKnifeFork from '@/assets/images/icon-diet-knife-fork.svg'
import iconEmail from '@/assets/images/icon-email.svg'
import iconEmailCircle from '@/assets/images/icon-email-circle.svg'
import iconEmailCircleOutline from '@/assets/images/icon-email-circle-outline.svg'
import iconFalling from '@/assets/images/icon-falling.svg'
import iconHeartbeatNoCircle from '@/assets/images/icon-heartbeat-no-circle.svg'
import iconHeartShield300k20years from '@/assets/images/icon-shield-300k.svg'
import iconHeartWithEkg from '@/assets/images/icon-heart-with-ekg.svg'
import iconHobbies from '@/assets/images/icon-hobbies.svg'
import iconHome from '@/assets/images/icon-home.svg'
import iconInfinity from '@/assets/images/icon-infinity.svg'
import iconLaptop from '@/assets/images/icon-laptop.svg'
import iconLessThanOneHour from '@/assets/images/icon-less-than-one-hour.svg'
import iconLightBulb from '@/assets/images/icon-light-bulb.svg'
import iconLocationPin from '@/assets/images/icon-location.svg'
import iconLocationPinBottomBorder from '@/assets/images/icon-location-pin-bottom-border.svg'
import iconLock from '@/assets/images/icon-lock.svg'
import iconNo from '@/assets/images/icon-x-outline-black.svg'
import iconNumberOneHashtag from '@/assets/images/icon-number-1-hashtag.svg'
import iconPause from '@/assets/images/icon-pause.svg'
import iconPersonHeart from '@/assets/images/icon-person-heart.svg'
import iconPhone from '@/assets/images/icon-phone.svg'
import iconPhoneCircle from '@/assets/images/icon-phone-circle.svg'
import iconPhoneCircleOutline from '@/assets/images/icon-phone-circle-outline.svg'
import iconPills from '@/assets/images/icon-pills.svg'
import iconPlay from '@/assets/images/icon-play.svg'
import iconPrinter from '@/assets/images/icon-printer.svg'
import iconQuestionMark from '@/assets/images/icon-question-mark.svg'
import iconQuestionMarkInTalkBubble from '@/assets/images/icon-question-mark-in-talk-bubble.svg'
import iconQuotes from '@/assets/images/icon-quotes.svg'
import iconQuotesWhite from '@/assets/images/icon-quotes-white.svg'
import iconShieldHeart from '@/assets/images/icon-shield-heart.svg'
import iconTextMessage from '@/assets/images/icon-text-message.svg'
import iconTimer from '@/assets/images/icon-timer.svg'
import iconTooltipInfo from '@/assets/images/icon-tooltip-info.svg'
import iconTriangleAlert from '@/assets/images/icon-triangle-alert.svg'
import iconXCircled from '@/assets/images/icon-x-circled.svg'
import iconYes from '@/assets/images/icon-check-outlined-black.svg'
import iconWatchmanDevice from '@/assets/images/icon-watchman-device.svg'
import logoWMBrandingArrows from '@/assets/images/logo-wm-branding-arrows.svg'

export default {
  graphicBrochureCover,
  graphicDayOfProcedureStep1,
  graphicDayOfProcedureStep2,
  graphicDayOfProcedureStep3,
  graphicDayOfProcedureStep4,
  graphicDDGUnqualified,
  graphicDiscussionGuide,
  graphicHeartLaa,
  graphicKnowYourRisks,
  graphicLaaWithImplant,
  graphicPhysicianGenericHeadshot,
  graphicPreviewDDG,
  graphicPreviewDDGEvolution,
  graphicQuarterWithWm,
  graphicSpeedyGrandson,
  graphicVideoThumbnailBackground,
  graphicWomanTalkingWithDoctor,
  graphicMosaicPicturesOrangeBoxArrow,
  graphicMosaicPicturesDesktop1,
  graphicMosaicPicturesDesktop2,
  graphicMosaicPicturesDesktop3,
  graphicMosaicPicturesDesktop4,
  graphicMosaicPicturesDesktop5,
  graphicMosaicPicturesDesktop6,
  graphicMosaicPicturesMobile,
  graphicBecomeAmbassadorBannerMobile,
  graphicBecomeAmbassadorBannerTablet,
  graphicBecomeAmbassadorBannerDesktop,
  icon24Hours,
  icon300kLivesChanged,
  icon3people,
  iconArrow,
  iconArrowCircle,
  iconArrowCircleFilled,
  iconArrowCircleOutlined,
  iconBirthdayCake,
  iconBleeding,
  iconBloodDrop,
  iconBloodThinners,
  iconBrowserMouse,
  iconCaret,
  iconChatFacebook,
  iconChatFilled,
  iconChatOutlined,
  iconCheck,
  iconCheckHeavy,
  iconChecklist,
  iconCheckmarkCircle,
  iconCheckScallop,
  iconCircleMinus,
  iconCircleNo,
  iconCircleNumberFour,
  iconCircleNumberOne,
  iconCircleNumberOpaqueFour,
  iconCircleNumberOpaqueThree,
  iconCircleNumberOpaqueTwo,
  iconCircleNumberThree,
  iconCircleNumberTwo,
  iconCirclePlus,
  iconClipboard,
  iconClock,
  iconClockArrowCircular,
  iconCloseX,
  iconContactHeadphones,
  iconCustomerServiceRep,
  iconDietKnifeFork,
  iconEmail,
  iconEmailCircle,
  iconEmailCircleOutline,
  iconFalling,
  iconHeartbeatNoCircle,
  iconHeartShield300k20years,
  iconHeartWithEkg,
  iconHobbies,
  iconHome,
  iconInfinity,
  iconLaptop,
  iconLessThanOneHour,
  iconLightBulb,
  iconLocationPin,
  iconLocationPinBottomBorder,
  iconLock,
  iconNo,
  iconNumberOneHashtag,
  iconPersonHeart,
  iconPause,
  iconPhone,
  iconPhoneCircle,
  iconPhoneCircleOutline,
  iconPills,
  iconPlay,
  iconPrinter,
  iconQuestionMark,
  iconQuestionMarkInTalkBubble,
  iconQuotes,
  iconQuotesWhite,
  iconShieldHeart,
  iconTextMessage,
  iconTimer,
  iconTooltipInfo,
  iconTriangleAlert,
  iconXCircled,
  iconYes,
  iconWatchmanDevice,
  logoWMBrandingArrows
}
