import helpers from '@/utils/helpers'
import { defineRule } from 'vee-validate'
import messages from '@/utils/validation-messages.config'

const required = (value, message) => {
  if (!value || !value.toString().length) {
    return message
  }
  return true
}

// const minLength = (value, limit, message) => {
//   if (!value || value.length < limit) {
//     return message
//   }
//   return true
// }

// const maxLength = (value, limit, message) => {
//   if (!value || value.length < limit) {
//     return message
//   }
//   return true
// }

const exactLength = (value, limit, message) => {
  if (value.length !== Number(limit)) {
    return message
  }
  return true
}

defineRule('max', (value, [limit]) => {
  if (!value || !value.length) {
    return true
  }
  if (value.length > limit) {
    return `Please enter no more than ${limit} characters.`
  }
  return true
})
defineRule('min', (value, [limit]) => {
  if (!value || !value.length) {
    return true
  }
  if (value.length < limit) {
    return `Please enter at least ${limit} characters.`
  }
  return true
})

// defineRule('min', min)
defineRule('valid-name-characters', (value) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true
  }
  const nameRule = new RegExp('^[a-zA-Z-_ \'."]+$')
  if (!nameRule.test(value)) {
    return messages.name_characters_invalid
  }
  return true
})

defineRule('valid-text-characters', (value) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true
  }
  const characterRule = /^[a-zA-Z-_ '."]+$/
  if (!characterRule.test(value)) {
    return messages.text_characters_invalid
  }
  return true
})

defineRule('email-required', (value) => {
  return required(value, messages.email_address_required)
})

defineRule('first-name-required', (value) => {
  return required(value, messages.first_name_required)
})

defineRule('last-name-required', (value) => {
  return required(value, messages.last_name_required)
})

defineRule('zip-required', (value) => {
  return required(value, messages.zip_code_required)
})

defineRule('email', (value) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true
  }
  // Check if email
  if (!/^[a-zA-Z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/.test(value)) {
    return messages.email_address_invalid
  }
  return true
})

defineRule('required', (value) => {
  return required(value, 'This field is required.')
})

defineRule('search-doctor-last-name', (value) => {
  if (value && value.length < 2) {
    return 'Enter a minimum of 2 characters.'
  }
  return true
})

defineRule('phone-number-length', (value, [limit]) => {
  // Field is empty, should pass since phone can be optional on some fields
  if (!value || !value.length) {
    return true
  }
  return exactLength(value, limit, messages.phone_number_length)
})

defineRule('zip-code-length', (value, [limit]) => {
  if (!value || !value.length) {
    return true
  }
  return exactLength(value, limit, messages.zip_code_length)
})

defineRule('dob-length', (value) => {
  return exactLength(value, 10, messages.dob_invalid)
})

defineRule('valid-date', (value) => {
  if (!value || !value.length) {
    return messages.date_invalid
  }
  return helpers.isDateValid(value) && helpers.isInValidDateRange(value) ? true : messages.date_invalid
})

defineRule('past-date', value => {
  const today = new Date()
  const theDate = new Date(value)
  if (today < theDate) {
    return messages.date_past_invalid
  }
  return true
})

defineRule('future-date', value => {
  const today = new Date()
  const theDate = new Date(value)
  if (today > theDate) {
    return messages.date_future_invalid
  }
  return true
})

defineRule('valid-age', (value) => {
  if (!value) {
    return messages.age_inappropriate
  }
  let age = helpers.calculateAge(value)
  return age >= 18 && age <= 120 ? true : messages.age_inappropriate
})

defineRule('required-generic', (value) => {
  return required(value, messages.generic.field_required)
})

defineRule('required-phone', (value) => {
  return required(value, messages.phone_number_required)
})

defineRule('required-dob', (value) => {
  return required(value, 'Date of birth is required.')
})

defineRule('required-birth-date', (value) => {
  return required(value, 'Birthdate is required.')
})

defineRule('required-implant-date', (value) => {
  return required(value, 'Implant date is required.')
})

defineRule('implanted-date-range', (value) => {
  if (!value) {
    return 'Implant date is required.'
  }
  const maxDate = new Date()
  const minDate = new Date('8/12/2002')
  const dateValue = new Date(value)
  return dateValue >= minDate && dateValue <= maxDate ? true : 'Please enter a valid date between 8/12/2002 and today.'
})

defineRule('required-gender', (value) => {
  return required(value, 'Gender is required.')
})

defineRule('required-large-textarea', (value) => {
  return required(value, messages.large_textarea_length_invalid)
})

defineRule('required-phone-number', (value) => {
  return required(value, messages.phone_number_invalid)
})

defineRule('required-phone-number-modal', (value) => {
  return required(value, messages.phone_modal_required)
})

defineRule('required-day-of-week', (value) => {
  return required(value, messages.ambassador.day_of_week)
})

defineRule('required-time-of-day', (value) => {
  return required(value, messages.ambassador.time_of_day)
})

defineRule('required-coordinator-token', (value) => {
  return required(value, messages.care_team.auth_invalid)
})

defineRule('required-radio', (value) => {
  return required(value, messages.generic.radio_button)
})

defineRule('required-checkbox', (value) => {
  return required(value, messages.generic.check_box)
})

defineRule('sms-terms-and-conditions', (value) => {
  return required(value, messages.sms_terms_and_conditions_required)
})

defineRule('sms-phone-number-required', (value) => {
  return required(value, messages.sms_phone_number_required)
})

defineRule('sms-consent-required', (value) => {
  return required(value, messages.sms_consent_required)
})
