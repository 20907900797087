<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<style lang="scss">
@import "./styles/variables";

.global-inner {
  max-width: $max-column-width;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.v-layout {
  z-index: 100 !important; // for v-app-bar inside v-layout
}
</style>

<script>
</script>
