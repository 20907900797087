/* eslint-disable no-unused-vars */
import axios from 'axios'
import survey from '@/mocks/survey'
import emails from '@/mocks/emails'
import patient from '@/mocks/patient'
import results from '@/mocks/results'
import zipCodes from '@/mocks/zipCodes'
import specialist from '@/mocks/specialist'
import physicians from '@/mocks/physicians'
import medicalCenters from '@/mocks/medicalCenters'
import surveyAlternative from '@/mocks/surveyAlternative'
import ambassadorMeeting from '@/mocks/ambassadorMeeting'
import cardiologist from '@/mocks/reengagement/cardiologist'
import campaign from '@/mocks/reengagement/campaign'
import appointment from '@/mocks/reengagement/appointment'
import identification from '@/mocks/reengagement/identification'
import motivational from '@/mocks/reengagement/motivational'
import incomplete from '@/mocks/reengagement/incomplete'
import nextstep from '@/mocks/reengagement/nextstep'
import feedback from '@/mocks/reengagement/feedback'
import closeloop from '@/mocks/reengagement/closeloop'
import ambassador from '@/mocks/reengagement/ambassador'
import unified from '@/mocks/reengagement/unified'
import followup from '@/mocks/reengagement/followup'
import lifechanger from '@/mocks/reengagement/lifechanger'
import surveySubmitMiniLqId1 from '@/mocks/surveySubmitMiniLqId1'
import surveyClickMiniLqId4 from '@/mocks/surveyClickMiniLqId4'
import surveyClickMiniLqId4Implanted from '@/mocks/surveyClickMiniLqId4_currentStageImplanted'
import surveyClickMiniLqId4QualifiedImplantingPhysicianName from '@/mocks/surveyClickMiniLqId4_currentStageQualifiedImplantingPhysicianName'
import surveyClickMiniLqId4QualifiedReferringPhysicianName from '@/mocks/surveyClickMiniLqId4_currentStageQualifiedReferringPhysicianName'
import eligibilitySurvey from '@/mocks/eligibilitySurvey2'
import therapyAdvocates from '@/mocks/therapyAdvocates'
import surveys from '@/mocks/resubmission/questionnaire/questionnaireResubmissionSurveys'

const MockAdapter = require('axios-mock-adapter')
let mock

let surveyWithHospital = JSON.parse(JSON.stringify(survey))
surveyWithHospital.hospital = 'Some Hospital'

const mocks = {
  initializeMocks: () => {
    mock = new MockAdapter(axios)

    /* Education Specialist Endpoint */
    mock.onPost(/^\/api\/specialists\/authenticate/).reply(function (config) {
      return specialist.specialistReply(config)
    })
    mock.onGet(/^\/api\/specialists\/surveys\/survey/).reply(function (config) {
      return specialist.getSpecialistsReply(config)
    })
    mock.onGet(/^\/api\/specialists\/authCheck/).reply(function (config) {
      return specialist.specialistAuthCheck(config)
    })

    /* Patient Ambassador Meeting Choice responses */
    mock.onGet(/^\/api\/phone\/ambassador\/request\/\beastern\b/).reply(200, ambassadorMeeting.easternTimeZone)
    mock.onGet(/^\/api\/phone\/ambassador\/request\/\bcentral\b/).reply(200, ambassadorMeeting.centralTimeZone)
    mock.onGet(/^\/api\/phone\/ambassador\/request\/\bcentraldst\b/).reply(200, ambassadorMeeting.centralTimeZoneDst)
    mock.onGet(/^\/api\/phone\/ambassador\/request\/\barizona\b/).reply(200, ambassadorMeeting.arizonaTimeZone)
    mock.onGet(/^\/api\/phone\/ambassador\/request\/\barizonadst\b/).reply(200, ambassadorMeeting.arizonaTimeZoneDst)
    mock.onGet(/^\/api\/phone\/ambassador\/request\/\bhawaiian\b/).reply(200, ambassadorMeeting.hawaiianTimeZone)

    /* Patient Ambassador Meeting Request Confirmation response */
    mock.onPost(/^\/api\/phone\/ambassador\/confirmation/).reply(200)

    /* Consent Record Only Form Endpoint */
    mock.onPost(/^\/api\/consentRecordOnly/).reply(200, {})

    /* Email Endpoints */
    mock.onGet(/^\/api\/emails\/\bphone/).reply(function (config) {
      return emails.emailReply(config)
    })
    mock.onGet(/^\/api\/emails\/\beligible/).reply(function (config) {
      return emails.emailReply(config)
    })
    mock.onGet(/^\/api\/emails\/\bnoPhone/).reply(function (config) {
      return emails.emailReply(config)
    })
    mock.onGet(/^\/api\/emails\/brochure/).reply(function (config) {
      return emails.emailReply(config)
    })

    /* External Email Specialist Endpoint */
    mock.onPost(/^\/api\/emails\/contact-specialist/).reply(function (config) {
      return emails.emailSpecialistReply(config)
    })

    /* Internal Email Specialist Endpoint */
    mock.onPost(/^\/api\/emails\/specialist/).reply(function (config) {
      return emails.emailInternalSpecialistReply(config)
    })

    mock.onPost(/^\/api\/emails/).reply(200)

    /* Physician Endpoints */
    mock.onGet(/^\/api\/physicians/).reply(200, physicians)

    /* Medical Center Endpoints */
    mock.onGet(/^\/api\/medical-centers\/nearest-three/).reply(200, medicalCenters)

    /* Cardio Now Ambassadors Endpoints */
    mock.onGet(/^\/api\/cardio-now\/ambassadors/).reply(200, therapyAdvocates)
    mock.onGet(/^\/api\/ambassadors/).reply(200, therapyAdvocates)

    /* Results Endpoints */
    mock.onPatch(/^\/api\/results/).reply(200)
    mock.onGet(/^\/api\/results\/\bphone\b/).reply(200, results.phoneNoPilotData)
    mock.onGet(/^\/api\/results\/\ball-questions\b/).reply(200, results.phoneNoPilotData)
    mock.onGet(/^\/api\/results\/\bno-optional-questions\b/).reply(200, results.phoneNoPilotData)
    mock.onGet(/^\/api\/results\/\bnone-of-the-above\b/).reply(200, results.phoneNoPilotData)
    mock.onGet(/^\/api\/results\/\bnoPhone\b/).reply(200, results.noPhoneNoPilotData)
    mock.onGet(/^\/api\/results\/\bphoneNoStageImplantData\b/).reply(200, results.phoneNoStageImplantDateData)
    mock.onGet(/^\/api\/results\/\bnoPhonePilot\b/).reply(200, results.noPhonePilotData)
    mock.onGet(/^\/api\/results\/\bconsentedPilot\b/).reply(200, results.consentedPilotData)
    mock.onGet(/^\/api\/results\/\beligible\b/).reply(200, results.eligibleData)
    mock.onGet(/^\/api\/results\/\beligibleFewerRationales\b/).reply(200, results.eligibleDataFewerRationales)
    mock.onGet(/^\/api\/results\/\bineligible\b/).reply(200, results.ineligibleData)
    mock.onGet(/^\/api\/results\/\beligibleNoPhone\b/).reply(200, results.eligibleNoPhoneData)
    mock.onGet(/^\/api\/results\/\bineligibleNoPhone\b/).reply(200, results.ineligibleNoPhoneData)
    mock.onGet(/^\/api\/results\/\bineligibleFailsAllCriteria\b/).reply(200, results.ineligibleFailsAllCriteriaData)
    mock.onGet(/^\/api\/results\/\bineligibleNoAfib\b/).reply(200, results.ineligibleNoAfibData)
    mock.onGet(/^\/api\/results\/\bineligibleNoBloodThinners\b/).reply(200, results.ineligibleNoBloodThinnersData)
    mock.onGet(/^\/api\/results\/\bineligibleInsufficientChadScore\b/).reply(200, results.ineligibleInsufficientChadScoreData)
    mock.onGet(/^\/api\/results\/\bineligibleNoAppropriateRationale\b/).reply(200, results.ineligibleNoAppropriateRationaleData)
    mock.onGet(/^\/api\/results\/\bhasBled\b/).reply(200, results.allHasBleedAndChadvasc)
    mock.onGet(/^\/api\/results\/\bchadVasc\b/).reply(200, results.allHasBleedAndChadvasc)
    mock.onGet(/^\/api\/results\/\bqualifiedCurrentStageEducated\b/).reply(200, results.qualifiedCurrentStageEducated)
    mock.onGet(/^\/api\/results\/\bqualifiedCurrentStageImplanterConsultation\b/).reply(200, results.qualifiedCurrentStageImplanterConsultation)
    mock.onGet(/^\/api\/results\/\bqualifiedCurrentStageImplanted\b/).reply(200, results.qualifiedCurrentStageImplanted)
    mock.onGet(/^\/api\/results\/\bqualifiedCurrentStageQualifiedImplantingPhysicianName\b/).reply(200, results.eligibleData)
    mock.onGet(/^\/api\/results\/\bqualifiedCurrentStageQualifiedReferringPhysicianName\b/).reply(200, results.eligibleData)

    /* Preview Endpoints */
    mock.onPatch(/^\/api\/preview/).reply(200)
    mock.onGet(/^\/api\/preview\/\bphone\b/).reply(200, results.phoneNoPilotData)
    mock.onGet(/^\/api\/preview\/\bnoPhone\b/).reply(200, results.noPhoneNoPilotData)
    mock.onGet(/^\/api\/preview\/\bineligible\b/).reply(200, results.ineligibleData)
    mock.onGet(/^\/api\/preview\/\beligible\b/).reply(200, results.eligibleData)

    /* Campaign Endpoints */
    mock.onGet(/^\/api\/surveys\/campaigns\/cardiologist/).reply(200, cardiologist)
    mock.onGet(/^\/api\/surveys\/campaigns\/appointment/).reply(200, appointment)
    mock.onGet(/^\/api\/surveys\/campaigns\/identification/).reply(200, identification)
    mock.onGet(/^\/api\/surveys\/campaigns\/motivational/).reply(200, motivational)
    mock.onGet(/^\/api\/surveys\/campaigns\/incomplete/).reply(200, incomplete)
    mock.onGet(/^\/api\/surveys\/campaigns\/next-step/).reply(200, nextstep)
    mock.onGet(/^\/api\/surveys\/campaigns\/feedback/).reply(200, feedback)
    mock.onGet(/^\/api\/surveys\/campaigns\/close-loop/).reply(200, closeloop)
    mock.onGet(/^\/api\/surveys\/campaigns\/ambassador/).reply(200, ambassador)
    mock.onGet(/^\/api\/surveys\/campaigns\/45day-followup/).reply(200, followup)
    mock.onGet(/^\/api\/surveys\/campaigns\/life-changer/).reply(200, lifechanger)
    mock.onGet(/^\/api\/surveys\/campaigns\/unified/).reply(200, unified)
    mock.onPost(/^\/api\/patient\/campaigns/).reply(function (config) {
      return campaign.campaignReply(config)
    })

    mock.onGet(/^\/api\/survey\b/).reply(function (config) {
      const patientId = config.params.patientId

      // Resubmit Survey mocks
      if (config.url.includes('resubmit')) {
        if (patientId === 'phone' || patientId === 'eligible' || patientId === 'no-optional-questions') {
          return [200, surveys.no_optional_questions]
        }
        if (config.params.patientId === 'all-questions') {
          return [200, surveys.all_questions]
        }
        if (config.params.patientId === 'none-of-the-above' || config.params.patientId === 'ineligible') {
          return [200, surveys.none_of_the_above_answered]
        }
      }

      // Survey V2 mocks
      switch (config.params.surveyId) {
        case '1':
          return [200, surveySubmitMiniLqId1]
        case '2':
          return [200, eligibilitySurvey]
        case '4':
          switch (patientId) {
            case 'qualifiedCurrentStageImplanted':
              return [200, surveyClickMiniLqId4Implanted]
            case 'qualifiedCurrentStageQualifiedImplantingPhysicianName':
              return [200, surveyClickMiniLqId4QualifiedImplantingPhysicianName]
            case 'qualifiedCurrentStageQualifiedReferringPhysicianName':
              return [200, surveyClickMiniLqId4QualifiedReferringPhysicianName]
            default:
              return [200, surveyClickMiniLqId4]
          }
        default:
          break
      }
    })

    mock.onGet(/^\/api\/surveys\/survey\/1000/, { params: { cid: '12345' } }).reply(200, surveyWithHospital)
    mock.onGet(/^\/api\/surveys\/survey\/1000/).reply(200, survey)
    mock.onGet(/^\/api\/surveys\/survey\/14000/).reply(200, surveyAlternative)

    mock.onPost(/^\/api\/patient\/survey-response/).reply(200)
    mock.onPost(/^\/api\/patient\/survey-resubmit/)
      .reply(200, JSON.parse(JSON.stringify({ eligible: true })))

    /* Zip Code Endpoints */
    mock.onGet(/^\/api\/zip\/02143/).reply(200, zipCodes.zip02143)
    mock.onGet(/^\/api\/zip\/02114/).reply(200, zipCodes.zip02114)

    /* Patient lookup by email and zipcode endpoint */
    mock.onPost(/^\/api\/patient\/auth/).reply(function (config) {
      return patient.patientLookupReply(config)
    })
    mock.onPost(/^\/api\/patient\/auth\/email/).reply(400)
    mock.onPost(/^\/api\/patient\/auth\/email\?emailType=*/).reply(function (config) {
      return patient.patientLookupByEmailReply(config)
    })
    /* Patient Endpoint */
    mock.onPost(/^\/api\/patient/).reply(function (config) {
      return patient.patientReply(config)
    })

    /* Patient ID by Patient Email endpoint */
    mock.onGet(/^\/api\/patient\/patientId/).reply(function (config) {
      return patient.patientIdReply(config)
    })

    /* Digitally Educated endpoint */
    mock.onGet(/^\/api\/digitally-educated\/*/).reply(200)

    /* Therapy Advocate Registration endpoint */
    mock.onPost(/^\/api\/therapy-advocate-registration/).reply(200)
  }
}

export default mocks
