import sessionHelper from '@/utils/storage/sessionStorage-helper'

export default {
  install (app) {
    app.config.globalProperties.$hasPatientId = () => {
      return !!sessionHelper.getHubSessionId()
    }
    app.config.globalProperties.$getPatientId = () => {
      return sessionHelper.getHubSessionId()
    }
  }
}
